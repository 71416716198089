import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoutes from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { WebSocketProvider } from './context/WebSocketContext';
import { RantProvider } from './context/RantContext';
import { MessageProvider } from './context/MessageContext';
import { NotificationsProvider } from './context/NotificationsContext';
import { Toaster } from 'react-hot-toast';
import * as serviceWorkerRegistration from './utils/serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <WebSocketProvider>
        <RantProvider>
          <MessageProvider>
            <NotificationsProvider>
              <Toaster position="top-center" />
              <AppRoutes />
            </NotificationsProvider>
          </MessageProvider>
        </RantProvider>
      </WebSocketProvider>
    </AuthProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note that this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
