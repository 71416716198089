import React from 'react';
import { useAuth } from '../context/AuthContext';
import { useRants } from '../context/RantContext';
import api from '../utils/axiosConfig';
import { toast } from 'react-hot-toast';
import { ChatBubbleLeftIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { HandThumbUpIcon as HandThumbUpIconSolid } from '@heroicons/react/24/solid';

const VoteButtons = ({ rantId, likes = [], commentCount = 0, onVoteUpdate, showCommentBubble = false }) => {
  const { isAuthenticated, user } = useAuth();
  const { handleVoteUpdate: contextHandleVoteUpdate } = useRants();
  const isLiked = isAuthenticated && Array.isArray(likes) && likes.includes(user?._id);
  const likeCount = Array.isArray(likes) ? likes.length : 0;

  const handleVote = async (e) => {
    e.stopPropagation(); // Prevent event bubbling
    
    if (!isAuthenticated) {
      toast.error('Please login to vote');
      return;
    }

    try {
      const response = await api.post(`/rants/${rantId}/like`);
      
      if (response.data.success) {
        // Create a rant-like object that matches what the components expect
        const updatedRant = {
          _id: rantId,
          likes: response.data.likes
        };

        // Update both the local state and context
        if (onVoteUpdate) {
          onVoteUpdate(updatedRant);
        }
        contextHandleVoteUpdate(updatedRant);
      } else {
        throw new Error(response.data.message || 'Failed to vote');
      }
    } catch (error) {
      console.error('Error voting:', error);
      toast.error(error.response?.data?.message || 'Failed to vote');
    }
  };

  return (
    <div className="flex items-center space-x-4" onClick={e => e.stopPropagation()}>
      <button
        onClick={handleVote}
        className={`flex items-center space-x-1 px-2 py-1 rounded-lg transition-colors ${
          isLiked
            ? 'text-blue-500 bg-blue-50 hover:bg-blue-100'
            : 'text-gray-500 hover:text-blue-500 hover:bg-blue-50'
        }`}
      >
        {isLiked ? (
          <HandThumbUpIconSolid className="w-5 h-5" />
        ) : (
          <HandThumbUpIcon className="w-5 h-5" />
        )}
        <span>{likeCount}</span>
      </button>

      {showCommentBubble && (
        <div className="relative">
          <ChatBubbleLeftIcon className="w-5 h-5 text-gray-500" />
          {commentCount > 0 && (
            <span className="absolute -top-2 -right-2 bg-blue-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
              {commentCount}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default VoteButtons; 