import axios from 'axios';
import { toast } from 'react-hot-toast';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5001/api',
  timeout: 15000,
  withCredentials: true
});

// Retry configuration
const MAX_RETRIES = 2;
const isRetryableError = (error) => {
  return (
    axios.isAxiosError(error) &&
    (!error.response || // Network errors
    error.code === 'ECONNABORTED' || // Timeout
    (error.response && (error.response.status === 408 || error.response.status === 500))) // Timeout or server error
  );
};

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Set longer timeout for URL metadata requests
    if (config.url?.includes('/url-metadata')) {
      config.timeout = 30000; // 30 seconds for URL metadata
    }

    console.log('API Request:', { url: config.url, method: config.method });
    return config;
  },
  (error) => {
    console.error('API Request Error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor with retry logic
api.interceptors.response.use(
  (response) => {
    console.log('API Response:', { url: response.config.url, status: response.status });
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Initialize retry count if not exists
    if (typeof originalRequest._retry === 'undefined') {
      originalRequest._retry = 0;
    }

    // Check if we should retry the request
    if (isRetryableError(error) && originalRequest._retry < MAX_RETRIES) {
      originalRequest._retry++;
      
      // Exponential backoff delay
      const delay = Math.min(1000 * (2 ** originalRequest._retry), 10000);
      
      await new Promise(resolve => setTimeout(resolve, delay));
      
      // Retry the request
      return api(originalRequest);
    }

    // Handle network errors
    if (!error.response) {
      if (error.code === 'ECONNABORTED') {
        toast.error('Request timed out. Please try again.');
      } else {
        toast.error('Network error. Please check your connection.');
      }
      return Promise.reject(error);
    }

    // Handle authentication errors
    if (error.response.status === 401) {
      localStorage.removeItem('auth_token');
      window.location.href = '/login';
      return Promise.reject(error);
    }

    // Handle server errors
    if (error.response.status >= 500) {
      toast.error('Server error. Please try again later.');
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default api; 