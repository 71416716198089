import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';
import { Tab } from '@headlessui/react';
import RantCard from '../components/RantCard';
import { ChatBubbleLeftIcon, HandThumbUpIcon, RssIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import api from '../utils/api';
import { getUploadUrl } from '../utils/helpers';
import BackButton from '../components/BackButton';
import ConfirmModal from '../components/ConfirmModal';

export default function UserProfile() {
  const { identifier } = useParams();
  const { user: currentUser } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [userContent, setUserContent] = useState({
    rants: [],
    likes: [],
    comments: []
  });
  const [contentLoading, setContentLoading] = useState(false);
  const [isMate, setIsMate] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [mates, setMates] = useState([]);
  const [requestPending, setRequestPending] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasReceivedRequest, setHasReceivedRequest] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        setError(null);

        let response;
        if (identifier.startsWith('@')) {
          response = await api.get(`/users/lookup/${identifier.substring(1)}`);
        } else {
          response = await api.get(`/users/${identifier}`);
        }

        setUser(response.data);

        // Fetch user stats
        const statsResponse = await api.get(`/users/${response.data._id}/stats`);
        setStats(statsResponse.data);

        // Fetch mate and request status
        if (currentUser && currentUser._id !== response.data._id) {
          const [mateRes, requestRes] = await Promise.all([
            api.get(`/mates/status/${response.data._id}`),
            api.get(`/mates/request/${response.data._id}`)
          ]);

          setIsMate(mateRes.data.isMate);
          setIsFollowing(mateRes.data.isMate ? true : mateRes.data.isFollowing);
          setRequestPending(requestRes.data.hasSentRequest);
          setHasReceivedRequest(requestRes.data.hasReceivedRequest);
        }

        // Fetch user content
        await fetchUserContent(response.data._id);
      } catch (error) {
        console.error('Error fetching user:', error);
        setError('Failed to load user profile');
        toast.error('Failed to load user profile');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [identifier, currentUser]);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (!currentUser || !user) return;
      try {
        // Get mate status first
        const mateRes = await api.get(`/mates/status/${user._id}`);
        setIsMate(mateRes.data.isMate);
        setRequestPending(mateRes.data.isPending);

        // Only check follow status if not mates (since mates are auto-following)
        if (!mateRes.data.isMate) {
          const followRes = await api.get(`/users/${user._id}/follow-status`);
          setIsFollowing(followRes.data.isFollowing);
        } else {
          setIsFollowing(true); // Mates are automatically following
        }
      } catch (error) {
        console.error('Error checking user status:', error);
      }
    };

    checkUserStatus();
  }, [currentUser, user]);

  const handleMateToggle = async () => {
    if (!currentUser) {
      toast.error('Please log in to add mates');
      return;
    }

    if (isMate) {
      setShowConfirmModal(true);
      return;
    }

    try {
      if (requestPending) {
        // Cancel request
        await api.post(`/mates/cancel/${user._id}`);
        setRequestPending(false);
        toast.success(`Cancelled mate request to ${user.username}`);
      } else if (hasReceivedRequest) {
        // Accept mate request
        await api.post(`/mates/accept/${user._id}`);
        setHasReceivedRequest(false);
        setIsMate(true);
        setIsFollowing(true);
        toast.success(`Accepted mate request from ${user.username}`);
      } else {
        // Send new mate request and automatically follow
        try {
          await api.post(`/mates/request/${user._id}`);
          
          // Check follow status before trying to follow
          const followStatus = await api.get(`/users/${user._id}/follow-status`);
          if (!followStatus.data.isFollowing) {
            try {
              await api.post(`/users/${user._id}/follow`);
              setIsFollowing(true);
            } catch (followError) {
              console.error('Follow error:', followError);
              // Don't fail if follow fails
            }
          }
          
          setRequestPending(true);
          toast.success(`Mate request sent to ${user.username}`);
        } catch (error) {
          if (error.response?.data?.message === 'Mate request already sent') {
            setRequestPending(true);
            toast.success(`Mate request already sent to ${user.username}`);
          } else {
            throw error;
          }
        }
      }
    } catch (error) {
      console.error('Error toggling mate status:', error);
      toast.error(error.response?.data?.message || 'Failed to update mate status');
    }
  };

  const handleConfirmRemoveMate = async () => {
    try {
      await api.delete(`/mates/${user._id}`);
      setIsMate(false);
      setIsFollowing(false);
      toast.success(`Removed ${user.username} from mates`);
    } catch (error) {
      console.error('Error removing mate:', error);
      toast.error(error.response?.data?.message || 'Failed to remove mate');
    }
  };

  const handleFollowToggle = async () => {
    if (!currentUser) {
      toast.error('Please log in to follow users');
      return;
    }

    try {
      if (isFollowing) {
        await api.delete(`/users/${user._id}/follow`);
        setIsFollowing(false);
        toast.success(`Unfollowed ${user.username}`);
      } else {
        await api.post(`/users/${user._id}/follow`);
        setIsFollowing(true);
        toast.success(`Now following ${user.username}`);
      }
    } catch (error) {
      console.error('Error toggling follow status:', error);
      toast.error(error.response?.data?.message || 'Failed to update follow status');
    }
  };

  const fetchUserContent = async (userId) => {
    if (!userId) return;
    
    setContentLoading(true);
    try {
      const [rantsRes, likesRes, commentsRes, matesRes] = await Promise.all([
        api.get(`/users/${userId}/rants`),
        api.get(`/users/${userId}/likes`),
        api.get(`/users/${userId}/comments`),
        api.get(`/users/${userId}/mates`)
      ]);

      setUserContent({
        rants: rantsRes.data || [],
        likes: likesRes.data || [],
        comments: (commentsRes.data || []).filter(comment => comment && comment.rant)
      });
      setMates(matesRes.data || []);
    } catch (error) {
      console.error('Error fetching user content:', error);
      setUserContent({
        rants: [],
        likes: [],
        comments: []
      });
      setMates([]);
    } finally {
      setContentLoading(false);
    }
  };

  const handleVoteUpdate = async (updatedRant) => {
    // Update the rant in both rants and likes arrays
    setUserContent(prev => ({
      ...prev,
      rants: prev.rants.map(rant => 
        rant._id === updatedRant._id ? { ...rant, likes: updatedRant.likes } : rant
      ),
      likes: prev.likes.map(rant => 
        rant._id === updatedRant._id ? { ...rant, likes: updatedRant.likes } : rant
      )
    }));

    // Update stats if needed
    if (user?._id) {
      try {
        const statsResponse = await api.get(`/users/${user._id}/stats`);
        setStats(statsResponse.data);
      } catch (error) {
        console.error('Error updating stats:', error);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !user) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="text-xl font-semibold text-gray-800 mb-4">
          {error || 'User not found'}
        </div>
        <button
          onClick={() => navigate('/')}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Go Home
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-white">
      <div className="max-w-4xl mx-auto px-4 pt-4">
        <BackButton />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-sm overflow-hidden"
        >
          <div className="relative h-32 bg-gradient-to-r from-blue-500 to-purple-600">
            {/* Profile Image */}
            <div className="absolute -bottom-16 left-6">
              <div className="w-32 h-32 rounded-full border-4 border-white overflow-hidden bg-white">
                {user.profileImage ? (
                  <img
                    src={getUploadUrl(user.profileImage)}
                    alt={user.username}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `https://ui-avatars.com/api/?name=${user.username}&background=random`;
                    }}
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-blue-500 to-purple-600 text-white text-4xl font-bold">
                    {user.username.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pt-20 px-6">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">@{user.username}</h1>
                {user.bio && (
                  <p className="mt-2 text-gray-600">{user.bio}</p>
                )}
              </div>
            </div>

            <div className="flex items-center gap-4 mt-4 mb-6">
              {currentUser && currentUser._id !== user._id && (
                <>
                  <button
                    onClick={handleMateToggle}
                    className={`
                      px-4 py-2 rounded-lg font-medium text-sm
                      ${isMate 
                        ? 'bg-gray-100 text-red-500 hover:bg-gray-200'
                        : requestPending
                        ? 'bg-gray-100 text-gray-500 hover:bg-gray-200'
                        : hasReceivedRequest
                        ? 'bg-gradient-to-r from-green-500 to-blue-500 text-white hover:shadow-md'
                        : 'bg-gradient-to-r from-purple-500 to-blue-500 text-white hover:shadow-md'
                      }
                      transition-all
                    `}
                  >
                    {isMate 
                      ? 'Remove Mate'
                      : requestPending
                      ? 'Cancel Request'
                      : hasReceivedRequest
                      ? 'Accept Request'
                      : 'Add Mate'
                    }
                  </button>

                  {isMate && (
                    <button
                      onClick={() => navigate(`/messages/${user._id}`)}
                      className="inline-flex items-center px-4 py-2.5 rounded-xl font-medium text-sm bg-blue-500 text-white hover:bg-blue-600 transition-all duration-200 shadow-md hover:shadow-lg"
                    >
                      <ChatBubbleLeftIcon className="w-4 h-4 mr-2" />
                      Message
                    </button>
                  )}

                  {!isMate && (
                    <button
                      onClick={handleFollowToggle}
                      className={`inline-flex items-center px-4 py-2.5 rounded-xl font-medium text-sm transition-all duration-200 ${
                        isFollowing
                          ? 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {isFollowing ? 'Unfollow' : 'Follow'}
                    </button>
                  )}
                </>
              )}
            </div>

            {/* Stats */}
            {stats && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-xl p-6 text-white shadow-lg"
                >
                  <div className="text-3xl font-bold mb-1">{stats.rantCount}</div>
                  <div className="text-blue-100 font-medium">Rants</div>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="bg-gradient-to-br from-purple-500 to-purple-600 rounded-xl p-6 text-white shadow-lg"
                >
                  <div className="text-3xl font-bold mb-1">{stats.commentCount}</div>
                  <div className="text-purple-100 font-medium">Comments</div>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="bg-gradient-to-br from-green-500 to-green-600 rounded-xl p-6 text-white shadow-lg"
                >
                  <div className="text-3xl font-bold mb-1">{stats.totalLikes}</div>
                  <div className="text-green-100 font-medium">Likes</div>
                </motion.div>
              </div>
            )}

            {/* Tabbed Content */}
            <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
              <Tab.List className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 rounded-xl bg-gray-100 p-2 mb-6">
                <Tab
                  className={({ selected }) =>
                    `w-full flex items-center justify-center py-2.5 text-sm font-medium leading-5 rounded-lg transition-all duration-200
                    ${selected
                      ? 'bg-white text-blue-600 shadow'
                      : 'text-gray-600 hover:text-blue-600 hover:bg-white/[0.12]'
                    }`
                  }
                >
                  <RssIcon className="w-5 h-5 mr-2" />
                  Rants
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full flex items-center justify-center py-2.5 text-sm font-medium leading-5 rounded-lg transition-all duration-200
                    ${selected
                      ? 'bg-white text-blue-600 shadow'
                      : 'text-gray-600 hover:text-blue-600 hover:bg-white/[0.12]'
                    }`
                  }
                >
                  <HandThumbUpIcon className="w-5 h-5 mr-2" />
                  Likes
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full flex items-center justify-center py-2.5 text-sm font-medium leading-5 rounded-lg transition-all duration-200
                    ${selected
                      ? 'bg-white text-blue-600 shadow'
                      : 'text-gray-600 hover:text-blue-600 hover:bg-white/[0.12]'
                    }`
                  }
                >
                  <ChatBubbleLeftIcon className="w-5 h-5 mr-2" />
                  Comments
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full flex items-center justify-center py-2.5 text-sm font-medium leading-5 rounded-lg transition-all duration-200
                    ${selected
                      ? 'bg-white text-blue-600 shadow'
                      : 'text-gray-600 hover:text-blue-600 hover:bg-white/[0.12]'
                    }`
                  }
                >
                  <UserGroupIcon className="w-5 h-5 mr-2" />
                  Mates
                </Tab>
              </Tab.List>

              <Tab.Panels className="mt-4">
                {contentLoading ? (
                  <div className="flex justify-center items-center py-12">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                  </div>
                ) : (
                  <>
                    <Tab.Panel>
                      {userContent.rants.length > 0 ? (
                        <div className="space-y-4">
                          {userContent.rants.map(rant => (
                            <RantCard 
                              key={rant._id} 
                              rant={rant} 
                              onVoteUpdate={handleVoteUpdate}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="text-center py-12">
                          <RssIcon className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                          <p className="text-gray-500">No rants yet</p>
                        </div>
                      )}
                    </Tab.Panel>

                    <Tab.Panel>
                      {userContent.likes.length > 0 ? (
                        <div className="space-y-4">
                          {userContent.likes.map(rant => (
                            <RantCard 
                              key={rant._id} 
                              rant={rant} 
                              onVoteUpdate={handleVoteUpdate}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="text-center py-12">
                          <HandThumbUpIcon className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                          <p className="text-gray-500">No liked rants yet</p>
                        </div>
                      )}
                    </Tab.Panel>

                    <Tab.Panel>
                      {Array.isArray(userContent.comments) && userContent.comments.length > 0 ? (
                        <div className="space-y-4">
                          {userContent.comments.map(comment => {
                            if (!comment || !comment.rant) return null;
                            return (
                              <motion.div
                                key={comment._id}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                className="bg-white rounded-xl shadow-sm border border-gray-100 p-4"
                              >
                                <div className="text-sm text-gray-500 mb-2">
                                  Commented on {formatDistanceToNow(new Date(comment.createdAt), { addSuffix: true })}
                                </div>
                                <p className="text-gray-800 mb-3">{comment.content}</p>
                                <Link
                                  to={`/rant/${comment.rant._id}`}
                                  className="text-blue-600 hover:text-blue-700 text-sm font-medium"
                                >
                                  View Rant →
                                </Link>
                              </motion.div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="text-center py-12">
                          <ChatBubbleLeftIcon className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                          <p className="text-gray-500">No comments yet</p>
                        </div>
                      )}
                    </Tab.Panel>

                    <Tab.Panel>
                      {mates.length > 0 ? (
                        <div className="flex flex-col space-y-2 w-full">
                          {mates.map(mate => (
                            <Link
                              key={mate._id}
                              to={`/profile/${mate.username}`}
                              className="flex items-center p-4 w-full rounded-xl hover:bg-gray-50 transition-colors border border-gray-100 shadow-sm"
                            >
                              <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-100 flex-shrink-0">
                                {mate.profileImage ? (
                                  <img
                                    src={getUploadUrl(mate.profileImage)}
                                    alt={mate.username}
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-blue-500 to-purple-600 text-white font-bold">
                                    {mate.username[0].toUpperCase()}
                                  </div>
                                )}
                              </div>
                              <div className="ml-4 flex-1">
                                <span className="font-medium text-gray-900">@{mate.username}</span>
                              </div>
                              <div className="text-gray-400 flex-shrink-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                              </div>
                            </Link>
                          ))}
                        </div>
                      ) : (
                        <div className="text-center py-12">
                          <UserGroupIcon className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                          <p className="text-gray-500">No mates yet</p>
                        </div>
                      )}
                    </Tab.Panel>
                  </>
                )}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </motion.div>
      </div>
      
      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmRemoveMate}
        title="Remove Mate"
        message={`Are you sure you want to remove ${user?.username} from your mates? You will also stop following each other.`}
        confirmText="Remove"
        type="danger"
      />
    </div>
  );
} 