import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import api from '../utils/axiosConfig';
import { toast } from 'react-hot-toast';
import { FaUsers, FaComments, FaBullhorn, FaExclamationTriangle, FaChartLine, FaUserShield, FaEye } from 'react-icons/fa';
import { Line } from 'react-chartjs-2';
import { getUploadUrl } from '../utils/helpers';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { useWebSocket } from '../context/WebSocketContext';
import ConfirmModal from '../components/ConfirmModal';
import { CheckIcon, TrashIcon, ShieldExclamationIcon, UserMinusIcon, EyeIcon } from '@heroicons/react/24/outline';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Predefined categories (same as in CreateRant.js)
const CATEGORIES = [
  { id: 'general', name: 'General', description: 'General discussions and topics' },
  { id: 'tech', name: 'Technology', description: 'Tech news, gadgets, and software' },
  { id: 'politics', name: 'Politics', description: 'Political discussions and news' },
  { id: 'sports', name: 'Sports', description: 'Sports news and discussions' },
  { id: 'entertainment', name: 'Entertainment', description: 'Movies, TV, music, and pop culture' },
  { id: 'gaming', name: 'Gaming', description: 'Video games and gaming culture' },
  { id: 'food', name: 'Food', description: 'Food, cooking, and restaurants' },
  { id: 'health', name: 'Health', description: 'Health, fitness, and wellbeing' }
];

export default function AdminDashboard() {
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalRants: 0,
    totalComments: 0,
    reportedContent: { rants: 0, comments: 0 },
    userGrowth: [],
    categoryStats: {}
  });
  const [users, setUsers] = useState([]);
  const [reports, setReports] = useState({ rants: [], comments: [] });
  const [activeTab, setActiveTab] = useState('overview');
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [onlineAdmins, setOnlineAdmins] = useState([]);
  const [filterStatus, setFilterStatus] = useState('');
  const [commentFilterStatus, setCommentFilterStatus] = useState('');
  const messagesEndRef = useRef(null);
  const { socket } = useWebSocket();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);

  // Add new functions for handling report actions
  const handleUpdateReportStatus = async (reportId, status, notes = '') => {
    try {
      await api.put(`/admin/reports/${reportId}/status`, { status, notes });
      toast.success('Report status updated successfully');
      fetchAdminData();
    } catch (error) {
      console.error('Error updating report status:', error);
      toast.error('Failed to update report status');
    }
  };

  const handleReportAction = async (reportId, action, notes = '') => {
    // For ban actions, show confirmation modal first
    if (action === 'ban' || action === 'ban_and_delete') {
      setSelectedReport({ id: reportId, action });
      setConfirmAction(action);
      setShowConfirmModal(true);
      return;
    }

    try {
      // Use the correct API endpoint with admin prefix
      const response = await api.post(`/admin/reports/${reportId}/action`, { 
        action,
        notes
      });

      if (response.data) {
        toast.success('Report action processed successfully');
        fetchAdminData();
        setShowConfirmModal(false);
      }
    } catch (error) {
      console.error('Error processing report action:', error);
      const errorMessage = error.response?.data?.message || 'Failed to process report action';
      toast.error(errorMessage);
    }
  };

  const handleConfirmedAction = async () => {
    if (!selectedReport || !confirmAction) return;

    try {
      const response = await api.post(`/admin/reports/${selectedReport.id}/action`, {
        action: confirmAction
      });

      if (response.data) {
        toast.success('Report action processed successfully');
        fetchAdminData();
        setShowConfirmModal(false);
      }
    } catch (error) {
      console.error('Error processing report action:', error);
      const errorMessage = error.response?.data?.message || 'Failed to process report action';
      toast.error(errorMessage);
    }
  };

  const getConfirmModalProps = () => {
    if (!confirmAction || !selectedReport) return {};

    const isBanAndDelete = confirmAction === 'ban_and_delete';
    return {
      title: isBanAndDelete ? 'Ban User and Delete Content' : 'Ban User',
      message: isBanAndDelete
        ? 'This will permanently ban the user and delete all their content. This action cannot be undone.'
        : 'This will permanently ban the user. This action cannot be undone.',
      confirmText: isBanAndDelete ? 'Ban and Delete' : 'Ban User',
      type: 'danger'
    };
  };

  // Scroll to bottom of messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!socket) {
      console.log('No WebSocket connection available');
      return;
    }

    console.log('Setting up WebSocket message handlers');

    const handleMessage = (event) => {
      try {
        console.log('Raw WebSocket message:', event.data);
        const data = JSON.parse(event.data);
        console.log('Parsed WebSocket message:', data);
        
        if (data.type === 'adminMessage') {
          const messageData = data.data?.data || data.data;
          if (messageData) {
            setMessages(prev => {
              // Check if message already exists
              const messageExists = prev.some(m => 
                m.content === messageData.content && 
                m.sender === messageData.sender &&
                m.createdAt === messageData.createdAt
              );
              if (messageExists) {
                return prev;
              }
              return [...prev, messageData];
            });
            scrollToBottom();
          }
        }
      } catch (error) {
        console.error('Error handling message:', error);
      }
    };

    socket.addEventListener('message', handleMessage);

    return () => {
      socket.removeEventListener('message', handleMessage);
    };
  }, [socket]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    try {
      // Send message to server
      const response = await api.post('/admin/messages', {
        content: newMessage.trim(),
        sender: currentUser.username
      });

      // Update local state
      const newMessageObj = response.data;
      setMessages(prev => [...prev, newMessageObj]);
      setNewMessage('');
      scrollToBottom();

      // Send WebSocket notification
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({
          type: 'admin_message',
          data: newMessageObj
        }));
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    }
  };

  useEffect(() => {
    if (!currentUser?.isAdmin && currentUser?.role !== 'admin' && currentUser?.role !== 'superadmin') {
      navigate('/');
      return;
    }

    fetchAdminData();
  }, [currentUser, navigate]);

  const fetchAdminData = async () => {
    try {
      setLoading(true);
      
      // Fetch stats
      const statsRes = await api.get('/admin/stats');
      setStats(statsRes.data || {
        totalUsers: 0,
        totalRants: 0,
        totalComments: 0,
        reportedContent: { rants: 0, comments: 0 },
        userGrowth: [],
        categoryStats: {}
      });

      // Fetch users
      const usersRes = await api.get('/admin/users');
      setUsers(usersRes.data || []);

      // Fetch reports with error handling
      try {
        const reportsRes = await api.get('/admin/reports');
        console.log('Reports response:', reportsRes.data);
        if (reportsRes.data && typeof reportsRes.data === 'object') {
          setReports(reportsRes.data);
        } else {
          console.error('Invalid reports data format:', reportsRes.data);
          setReports({ rants: [], comments: [] });
        }
      } catch (reportsError) {
        console.error('Error fetching reports:', reportsError);
        setReports({ rants: [], comments: [] });
        toast.error('Failed to load reported content');
      }

    } catch (error) {
      console.error('Error fetching admin data:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      toast.error('Failed to load some admin data');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteContent = async (type, id) => {
    if (!window.confirm(`Are you sure you want to delete this ${type}?`)) return;
    
    try {
      await api.delete(`/admin/content/${type}/${id}`);
      toast.success(`${type} deleted successfully`);
      fetchAdminData();
    } catch (error) {
      console.error('Error deleting content:', error);
      toast.error('Failed to delete content');
    }
  };

  const handleUpdateUserRole = async (userId, newRole) => {
    if (!window.confirm(`Are you sure you want to change this user's role to ${newRole}?`)) return;

    try {
      await api.put(`/admin/users/${userId}/role`, { role: newRole });
      toast.success('User role updated successfully');
      fetchAdminData();
    } catch (error) {
      console.error('Error updating user role:', error);
      toast.error('Failed to update user role');
    }
  };

  const handleBanUser = async (userId, deleteContent = false) => {
    if (!window.confirm(`Are you sure you want to ban this user${deleteContent ? ' and delete all their content' : ''}?`)) return;

    try {
      await api.post(`/admin/users/${userId}/ban`, { deleteContent });
      toast.success(`User banned successfully${deleteContent ? ' and content deleted' : ''}`);
      fetchAdminData();
    } catch (error) {
      console.error('Error banning user:', error);
      toast.error('Failed to ban user');
    }
  };

  const handleUnbanUser = async (userId) => {
    if (!window.confirm('Are you sure you want to unban this user?')) return;

    try {
      await api.post(`/admin/users/${userId}/unban`);
      toast.success('User unbanned successfully');
      fetchAdminData();
    } catch (error) {
      console.error('Error unbanning user:', error);
      toast.error('Failed to unban user');
    }
  };

  // Add function to fetch messages
  const fetchAdminMessages = async () => {
    try {
      const response = await api.get('/admin/messages');
      setMessages(response.data);
      scrollToBottom();
    } catch (error) {
      console.error('Error fetching admin messages:', error);
      toast.error('Failed to load messages');
    }
  };

  // Fetch messages on component mount
  useEffect(() => {
    fetchAdminMessages();
  }, []);

  // Add WebSocket message handler
  useEffect(() => {
    if (!socket) return;

    const handleWebSocketMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'admin_message' && data.message) {
          setMessages(prev => [...prev, data.message]);
          scrollToBottom();
        }
      } catch (error) {
        console.error('Error handling WebSocket message:', error);
      }
    };

    socket.addEventListener('message', handleWebSocketMessage);
    return () => socket.removeEventListener('message', handleWebSocketMessage);
  }, [socket]);

  // Add function to navigate to rant
  const handleViewRant = (rantId) => {
    navigate(`/rant/${rantId}`);
  };

  // Add function to navigate to user profile
  const handleViewProfile = (username) => {
    navigate(`/profile/${username}`);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const chartData = {
    labels: stats?.userGrowth?.map(item => item._id) || [],
    datasets: [
      {
        label: 'New Users',
        data: stats?.userGrowth?.map(item => item.count) || [],
        fill: false,
        borderColor: 'rgb(59, 130, 246)',
        tension: 0.1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'User Growth (Last 7 Days)'
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 py-4 sm:py-8">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {/* Header */}
          <div className="p-4 sm:p-6 border-b">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-2 sm:space-y-0">
              <div>
                <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Admin Dashboard</h1>
                <p className="mt-1 text-sm text-gray-500">
                  Manage users, content, and view platform statistics
                </p>
              </div>
              <div className="flex items-center space-x-2 py-1">
                <span className="text-sm text-gray-600">Logged in as:</span>
                <span className="px-3 py-1 rounded-full bg-purple-100 text-purple-800 text-sm font-medium">
                  {currentUser?.role}
                </span>
              </div>
            </div>
          </div>

          {/* Navigation Tabs */}
          <div className="border-b">
            <nav className="flex flex-col sm:flex-row">
              {['overview', 'users', 'reports', 'categories', 'admin-chat'].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`py-3 px-4 text-sm font-medium border-l-2 sm:border-l-0 sm:border-b-2 flex items-center justify-between ${
                    activeTab === tab
                      ? 'border-blue-500 text-blue-600 bg-blue-50 sm:bg-transparent'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }`}
                >
                  <span className="capitalize">{tab.replace('-', ' ')}</span>
                  {tab === 'admin-chat' && (
                    <span className="flex h-2 w-2 ml-2">
                      <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-blue-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
                    </span>
                  )}
                </button>
              ))}
            </nav>
          </div>

          {/* Content */}
          <div className="p-2 sm:p-6">
            {activeTab === 'overview' && (
              <div className="space-y-4 sm:space-y-6">
                {/* Stats Grid */}
                <div className="p-2 sm:p-6">
                  <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-6">
                    <div className="bg-white p-3 sm:p-6 rounded-lg shadow-sm border">
                      <div className="flex items-center">
                        <FaUsers className="h-5 w-5 sm:h-8 sm:w-8 text-blue-500" />
                        <div className="ml-3 sm:ml-4">
                          <p className="text-xs sm:text-sm font-medium text-gray-500">Total Users</p>
                          <p className="text-lg sm:text-2xl font-semibold text-gray-900">{stats?.totalUsers || 0}</p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-3 sm:p-6 rounded-lg shadow-sm border">
                      <div className="flex items-center">
                        <FaBullhorn className="h-5 w-5 sm:h-8 sm:w-8 text-purple-500" />
                        <div className="ml-3 sm:ml-4">
                          <p className="text-xs sm:text-sm font-medium text-gray-500">Total Rants</p>
                          <p className="text-lg sm:text-2xl font-semibold text-gray-900">{stats?.totalRants || 0}</p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-3 sm:p-6 rounded-lg shadow-sm border">
                      <div className="flex items-center">
                        <FaComments className="h-5 w-5 sm:h-8 sm:w-8 text-green-500" />
                        <div className="ml-3 sm:ml-4">
                          <p className="text-xs sm:text-sm font-medium text-gray-500">Total Comments</p>
                          <p className="text-lg sm:text-2xl font-semibold text-gray-900">{stats?.totalComments || 0}</p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-3 sm:p-6 rounded-lg shadow-sm border">
                      <div className="flex items-center">
                        <FaExclamationTriangle className="h-5 w-5 sm:h-8 sm:w-8 text-red-500" />
                        <div className="ml-3 sm:ml-4">
                          <p className="text-xs sm:text-sm font-medium text-gray-500">Reported Content</p>
                          <p className="text-lg sm:text-2xl font-semibold text-gray-900">
                            {(stats?.reportedContent?.pending || 0)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Growth Chart */}
                <div className="bg-white p-3 sm:p-6 rounded-lg shadow-sm border mt-4">
                  <Line data={chartData} options={{
                    ...chartOptions,
                    maintainAspectRatio: false,
                    aspectRatio: window.innerWidth < 640 ? 1 : 2
                  }} 
                  height={window.innerWidth < 640 ? 200 : 300}
                  />
                </div>
              </div>
            )}

            {activeTab === 'admin-chat' && (
              <div className="bg-white rounded-lg shadow-lg h-[calc(100vh-14rem)] flex flex-col">
                <div className="p-3 sm:p-4 border-b bg-gradient-to-r from-purple-600 to-blue-500">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <FaUserShield className="text-white h-4 sm:h-5 w-4 sm:w-5" />
                      <h2 className="text-base sm:text-lg font-semibold text-white">Admin Chat</h2>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className="flex h-2 sm:h-3 w-2 sm:w-3">
                        <span className="animate-ping absolute inline-flex h-2 sm:h-3 w-2 sm:w-3 rounded-full bg-green-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-2 sm:h-3 w-2 sm:w-3 bg-green-500"></span>
                      </span>
                      <span className="text-xs sm:text-sm text-white">Online</span>
                    </div>
                  </div>
                </div>
                
                {/* Messages container */}
                <div className="flex-1 overflow-y-auto p-2 sm:p-4 bg-gray-50">
                  <div className="space-y-3 sm:space-y-4">
                    {messages.map((message, index) => {
                      const isOwnMessage = message.sender === currentUser.username;
                      const showAvatar = index === 0 || messages[index - 1].sender !== message.sender;
                      
                      return (
                        <div
                          key={message._id || index}
                          className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'}`}
                        >
                          <div className={`flex ${isOwnMessage ? 'flex-row-reverse' : 'flex-row'} max-w-[85%] sm:max-w-[80%] items-end space-x-1 sm:space-x-2`}>
                            {showAvatar && !isOwnMessage && (
                              <div className="flex-shrink-0 w-6 sm:w-8 h-6 sm:h-8 rounded-full bg-gradient-to-r from-purple-500 to-blue-500 flex items-center justify-center text-white text-xs sm:text-sm font-medium">
                                {message.sender[0].toUpperCase()}
                              </div>
                            )}
                            <div
                              className={`flex flex-col space-y-1 ${
                                isOwnMessage ? 'items-end mr-1 sm:mr-2' : 'items-start ml-1 sm:ml-2'
                              }`}
                            >
                              {showAvatar && (
                                <span className="text-xs text-gray-500">
                                  {message.sender}
                                </span>
                              )}
                              <div
                                className={`rounded-2xl px-3 sm:px-4 py-1.5 sm:py-2 max-w-sm break-words ${
                                  isOwnMessage
                                    ? 'bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-br-none'
                                    : 'bg-white border border-gray-200 rounded-bl-none'
                                }`}
                              >
                                <p className="text-xs sm:text-sm">{message.content}</p>
                                <p className={`text-[10px] sm:text-xs mt-1 ${isOwnMessage ? 'text-white/70' : 'text-gray-500'}`}>
                                  {new Date(message.createdAt).toLocaleTimeString([], { 
                                    hour: '2-digit', 
                                    minute: '2-digit'
                                  })}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div ref={messagesEndRef} />
                  </div>
                </div>

                {/* Message input */}
                <div className="p-2 sm:p-4 bg-white border-t">
                  <form onSubmit={handleSendMessage} className="flex space-x-2">
                    <input
                      type="text"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder="Type a message..."
                      className="flex-1 px-3 sm:px-4 py-2 text-sm bg-gray-100 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500/50"
                    />
                    <button
                      type="submit"
                      disabled={!newMessage.trim()}
                      className="px-4 sm:px-6 py-2 bg-gradient-to-r from-purple-600 to-blue-500 text-white text-sm rounded-xl hover:from-purple-700 hover:to-blue-600 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            )}

            {activeTab === 'users' && (
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full align-middle">
                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-2 sm:px-4 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            User
                          </th>
                          <th scope="col" className="px-2 sm:px-4 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Role
                          </th>
                          <th scope="col" className="px-2 sm:px-4 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">
                            Status
                          </th>
                          <th scope="col" className="px-2 sm:px-4 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">
                            Joined
                          </th>
                          <th scope="col" className="px-2 sm:px-4 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {(users || []).map((user) => (
                          <tr key={user._id}>
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="h-8 w-8 rounded-full overflow-hidden flex-shrink-0 cursor-pointer" onClick={() => handleViewProfile(user.username)}>
                                  {user.profileImage ? (
                                    <img
                                      src={getUploadUrl(user.profileImage)}
                                      alt={user.username}
                                      className="h-full w-full rounded-full object-cover"
                                    />
                                  ) : (
                                    <div className="h-full w-full rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white text-sm">
                                      {user.username[0].toUpperCase()}
                                    </div>
                                  )}
                                </div>
                                <div className="ml-2 sm:ml-3">
                                  <button
                                    onClick={() => handleViewProfile(user.username)}
                                    className="text-sm font-medium text-gray-900 hover:text-blue-600 truncate max-w-[120px] sm:max-w-none text-left"
                                  >
                                    {user.username}
                                  </button>
                                  <div className="text-sm text-gray-500 hidden sm:block truncate max-w-[150px] lg:max-w-none">
                                    {user.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap">
                              <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                user.role === 'admin' 
                                  ? 'bg-green-100 text-green-800'
                                  : user.role === 'superadmin'
                                  ? 'bg-purple-100 text-purple-800'
                                  : 'bg-gray-100 text-gray-800'
                              }`}>
                                {user.role}
                              </span>
                            </td>
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap hidden sm:table-cell">
                              <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                user.isBanned
                                  ? 'bg-red-100 text-red-800'
                                  : 'bg-green-100 text-green-800'
                              }`}>
                                {user.isBanned ? 'Banned' : 'Active'}
                              </span>
                            </td>
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                              {new Date(user.createdAt).toLocaleDateString()}
                            </td>
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap text-sm text-gray-500">
                              <div className="flex flex-wrap gap-2">
                                {currentUser?.role === 'superadmin' && user.role !== 'superadmin' && (
                                  <select
                                    value={user.role}
                                    onChange={(e) => handleUpdateUserRole(user._id, e.target.value)}
                                    className="block w-20 sm:w-24 pl-2 pr-6 py-1 text-xs sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
                                  >
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                  </select>
                                )}
                                {user.isBanned ? (
                                  <button
                                    onClick={() => handleUnbanUser(user._id)}
                                    className="px-2 sm:px-3 py-1 bg-green-100 text-green-800 rounded-md hover:bg-green-200 text-xs sm:text-sm whitespace-nowrap"
                                    disabled={user.role === 'superadmin'}
                                  >
                                    Unban
                                  </button>
                                ) : (
                                  <div className="flex flex-wrap gap-1 sm:gap-2">
                                    {((currentUser?.role === 'superadmin' && user.role !== 'superadmin') || 
                                      (currentUser?.role === 'admin' && user.role === 'user')) && (
                                        <>
                                          <button
                                            onClick={() => handleBanUser(user._id, false)}
                                            className="px-2 sm:px-3 py-1 bg-red-100 text-red-800 rounded-md hover:bg-red-200 text-xs sm:text-sm whitespace-nowrap"
                                          >
                                            Ban
                                          </button>
                                          <button
                                            onClick={() => handleBanUser(user._id, true)}
                                            className="px-2 sm:px-3 py-1 bg-red-100 text-red-800 rounded-md hover:bg-red-200 text-xs sm:text-sm whitespace-nowrap"
                                          >
                                            Ban & Delete
                                          </button>
                                        </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'reports' && (
              <div className="space-y-8">
                <div>
                  <h3 className="text-lg font-medium text-gray-900">Reported Rants</h3>
                  <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                    <ul className="divide-y divide-gray-200">
                      {reports.rants.filter(report => report.isVisible !== false).map((report) => (
                        <li key={report._id} className={`p-4 ${
                          report.status === 'flagged' ? 'bg-red-50' : ''
                        }`}>
                          <div className="flex flex-col space-y-4">
                            {/* Content and Report Info */}
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900">
                                {report.content?.content?.substring(0, 100)}...
                              </p>
                              <div className="mt-1 flex flex-wrap items-center gap-2">
                                <p className="text-sm text-gray-500">
                                  Reported by: {report.user?.username} | Reason: {report.reason}
                                </p>
                                {report.status && (
                                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                    report.status === 'flagged' ? 'bg-red-100 text-red-800' :
                                    report.status === 'ignored' ? 'bg-gray-100 text-gray-800' :
                                    report.status === 'deleted' ? 'bg-yellow-100 text-yellow-800' :
                                    report.status === 'banned' ? 'bg-purple-100 text-purple-800' :
                                    'bg-blue-100 text-blue-800'
                                  }`}>
                                    {report.status.charAt(0).toUpperCase() + report.status.slice(1)}
                                  </span>
                                )}
                              </div>
                              {report.details && (
                                <p className="text-sm text-gray-500 mt-1">
                                  Details: {report.details}
                                </p>
                              )}
                            </div>

                            {/* Action Buttons */}
                            <div className="flex flex-wrap gap-2">
                              <button
                                onClick={() => handleViewRant(report.content?._id)}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded-md"
                                title="View Content"
                              >
                                <EyeIcon className="h-5 w-5 mr-1" />
                                View
                              </button>
                              <button
                                onClick={() => handleReportAction(report._id, 'ignore')}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-green-600 hover:text-green-700 hover:bg-green-50 rounded-md"
                                title="Ignore Report"
                              >
                                <CheckIcon className="h-5 w-5 mr-1" />
                                Ignore
                              </button>
                              <button
                                onClick={() => handleReportAction(report._id, 'delete')}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md"
                                title="Delete Content"
                              >
                                <TrashIcon className="h-5 w-5 mr-1" />
                                Delete
                              </button>
                              <button
                                onClick={() => handleReportAction(report._id, 'flag')}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-yellow-600 hover:text-yellow-700 hover:bg-yellow-50 rounded-md"
                                title="Flag Content"
                              >
                                <ShieldExclamationIcon className="h-5 w-5 mr-1" />
                                Flag
                              </button>
                              <button
                                onClick={() => handleReportAction(report._id, 'ban')}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-orange-600 hover:text-orange-700 hover:bg-orange-50 rounded-md"
                                title="Ban User"
                              >
                                <UserMinusIcon className="h-5 w-5 mr-1" />
                                Ban
                              </button>
                              <button
                                onClick={() => handleReportAction(report._id, 'ban_and_delete')}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md"
                                title="Ban User and Delete All Content"
                              >
                                <UserMinusIcon className="h-5 w-5 mr-1" />
                                Ban & Delete All
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* Reported Comments section - using the same mobile-friendly layout */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900">Reported Comments</h3>
                  <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                    <ul className="divide-y divide-gray-200">
                      {reports.comments.filter(report => report.isVisible !== false).map((report) => (
                        <li key={report._id} className={`p-4 ${
                          report.status === 'flagged' ? 'bg-red-50' : ''
                        }`}>
                          <div className="flex flex-col space-y-4">
                            {/* Content and Report Info */}
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900">
                                {report.content?.content?.substring(0, 100)}...
                              </p>
                              <div className="mt-1 flex flex-wrap items-center gap-2">
                                <p className="text-sm text-gray-500">
                                  Reported by: {report.user?.username} | Reason: {report.reason}
                                </p>
                                {report.status && (
                                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                    report.status === 'flagged' ? 'bg-red-100 text-red-800' :
                                    report.status === 'ignored' ? 'bg-gray-100 text-gray-800' :
                                    report.status === 'deleted' ? 'bg-yellow-100 text-yellow-800' :
                                    report.status === 'banned' ? 'bg-purple-100 text-purple-800' :
                                    'bg-blue-100 text-blue-800'
                                  }`}>
                                    {report.status.charAt(0).toUpperCase() + report.status.slice(1)}
                                  </span>
                                )}
                              </div>
                              {report.details && (
                                <p className="text-sm text-gray-500 mt-1">
                                  Details: {report.details}
                                </p>
                              )}
                            </div>

                            {/* Action Buttons */}
                            <div className="flex flex-wrap gap-2">
                              <button
                                onClick={() => handleViewRant(report.content?._id)}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded-md"
                                title="View Content"
                              >
                                <EyeIcon className="h-5 w-5 mr-1" />
                                View
                              </button>
                              <button
                                onClick={() => handleReportAction(report._id, 'ignore')}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-green-600 hover:text-green-700 hover:bg-green-50 rounded-md"
                                title="Ignore Report"
                              >
                                <CheckIcon className="h-5 w-5 mr-1" />
                                Ignore
                              </button>
                              <button
                                onClick={() => handleReportAction(report._id, 'delete')}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md"
                                title="Delete Content"
                              >
                                <TrashIcon className="h-5 w-5 mr-1" />
                                Delete
                              </button>
                              <button
                                onClick={() => handleReportAction(report._id, 'flag')}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-yellow-600 hover:text-yellow-700 hover:bg-yellow-50 rounded-md"
                                title="Flag Content"
                              >
                                <ShieldExclamationIcon className="h-5 w-5 mr-1" />
                                Flag
                              </button>
                              <button
                                onClick={() => handleReportAction(report._id, 'ban')}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-orange-600 hover:text-orange-700 hover:bg-orange-50 rounded-md"
                                title="Ban User"
                              >
                                <UserMinusIcon className="h-5 w-5 mr-1" />
                                Ban
                              </button>
                              <button
                                onClick={() => handleReportAction(report._id, 'ban_and_delete')}
                                className="inline-flex items-center px-3 py-1.5 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md"
                                title="Ban User and Delete All Content"
                              >
                                <UserMinusIcon className="h-5 w-5 mr-1" />
                                Ban & Delete All
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'categories' && (
              <div className="p-2 sm:p-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                  {CATEGORIES.map(category => {
                    const count = stats.categoryStats?.[category.id] || 0;
                    const percentage = stats.totalRants ? (count / stats.totalRants * 100) : 0;
                    
                    return (
                      <div key={category.id} className="bg-white p-4 sm:p-6 rounded-lg shadow border">
                        <div className="flex items-center justify-between">
                          <div>
                            <h4 className="text-lg sm:text-xl font-semibold text-gray-900">{category.name}</h4>
                            <p className="text-xs sm:text-sm text-gray-500 mt-1">{category.description}</p>
                          </div>
                          <div className="text-right">
                            <p className="text-2xl sm:text-3xl font-bold text-blue-600">
                              {count}
                            </p>
                            <p className="text-xs sm:text-sm text-gray-500">rants</p>
                          </div>
                        </div>
                        <div className="mt-4">
                          <div className="w-full bg-gray-200 rounded-full h-2">
                            <div
                              className="bg-blue-600 h-2 rounded-full"
                              style={{
                                width: `${percentage}%`
                              }}
                            ></div>
                          </div>
                          <p className="text-xs text-gray-500 mt-1 text-right">
                            {percentage.toFixed(1)}% of total
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add ConfirmModal */}
      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmedAction}
        {...getConfirmModalProps()}
      />
    </div>
  );
} 