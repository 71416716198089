import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  EyeIcon,
  CheckIcon,
  XMarkIcon,
  ShieldExclamationIcon,
  UserMinusIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import ConfirmModal from '../../components/ConfirmModal';

function ReportedRants() {
  const [rants, setRants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedRant, setSelectedRant] = useState(null);
  const [filter, setFilter] = useState('pending');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);

  useEffect(() => {
    fetchReportedRants();
  }, [filter, page]);

  const fetchReportedRants = async () => {
    try {
      const response = await axios.get(`/api/rants/reports?status=${filter}&page=${page}`);
      setRants(response.data.rants);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (err) {
      setError('Failed to load reported rants');
      setLoading(false);
    }
  };

  const handleAction = async (reportId, action, notes = '') => {
    try {
      await axios.post(`/api/admin/reports/${reportId}/action`, {
        action,
        notes
      });
      fetchReportedRants();
      setShowConfirmModal(false);
    } catch (err) {
      setError('Failed to process report action');
    }
  };

  const handleBanAction = (report, action) => {
    setSelectedReport(report);
    setConfirmAction(action);
    setShowConfirmModal(true);
  };

  const getConfirmModalProps = () => {
    if (!confirmAction || !selectedReport) return {};

    const isBanAndDelete = confirmAction === 'ban_and_delete';
    return {
      title: isBanAndDelete ? 'Ban User and Delete Content' : 'Ban User',
      message: isBanAndDelete
        ? 'This will permanently ban the user and delete all their content. This action cannot be undone.'
        : 'This will permanently ban the user. This action cannot be undone.',
      confirmText: isBanAndDelete ? 'Ban and Delete' : 'Ban User',
      type: 'danger'
    };
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary-200 border-t-primary-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Reported Rants</h1>
        <p className="mt-2 text-sm text-gray-600">
          Review and moderate reported content
        </p>
      </div>

      {error && (
        <div className="mb-8 p-4 bg-red-50 border border-red-200 rounded-lg">
          <p className="text-red-600">{error}</p>
        </div>
      )}

      {/* Filter Tabs */}
      <div className="mb-8">
        <nav className="flex space-x-4" aria-label="Filter">
          {['pending', 'reviewed', 'resolved', 'rejected'].map((status) => (
            <button
              key={status}
              onClick={() => setFilter(status)}
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                filter === status
                  ? 'bg-primary-100 text-primary-700'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </button>
          ))}
        </nav>
      </div>

      {/* Rants List */}
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {rants.map((rant) => (
            <li key={rant._id}>
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {rant.content.substring(0, 100)}...
                    </p>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <span className="mr-4">Category: {rant.category}</span>
                      <span>Region: {rant.region}</span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => setSelectedRant(rant)}
                      className="text-gray-400 hover:text-gray-500"
                      title="View Details"
                    >
                      <EyeIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleAction(rant._id, 'ignore')}
                      className="text-green-400 hover:text-green-500"
                      title="Ignore Report"
                    >
                      <CheckIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleAction(rant._id, 'delete')}
                      className="text-red-400 hover:text-red-500"
                      title="Delete Content"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleAction(rant._id, 'flag')}
                      className="text-yellow-400 hover:text-yellow-500"
                      title="Flag Content"
                    >
                      <ShieldExclamationIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleBanAction(rant, 'ban')}
                      className="text-orange-400 hover:text-orange-500"
                      title="Ban User"
                    >
                      <UserMinusIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleBanAction(rant, 'ban_and_delete')}
                      className="text-red-600 hover:text-red-700"
                      title="Ban User and Delete All Content"
                    >
                      <UserMinusIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
                <div className="mt-2">
                  <div className="flex flex-wrap gap-2">
                    {rant.reports.map((report, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
                      >
                        {report.reason}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Confirm Modal */}
      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => selectedReport && handleAction(selectedReport._id, confirmAction)}
        {...getConfirmModalProps()}
      />

      {/* Pagination */}
      <div className="mt-8 flex justify-center">
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
          <button
            onClick={() => setPage(p => Math.max(1, p - 1))}
            disabled={page === 1}
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            Previous
          </button>
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => setPage(i + 1)}
              className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                page === i + 1
                  ? 'z-10 bg-primary-50 border-primary-500 text-primary-600'
                  : 'bg-white text-gray-500 hover:bg-gray-50'
              }`}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() => setPage(p => Math.min(totalPages, p + 1))}
            disabled={page === totalPages}
            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            Next
          </button>
        </nav>
      </div>
    </div>
  );
}

export default ReportedRants; 