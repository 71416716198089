import React from 'react';
import RantCard from './RantCard';
import { useRants } from '../context/RantContext';

const RantList = ({ rants, loading, error, lastRantRef }) => {
  if (loading && !rants.length) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-gray-600">
        {error}
      </div>
    );
  }

  if (!rants.length) {
    return (
      <div className="text-center py-8 text-gray-600">
        No rants found
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {rants.map((rant, index) => {
        if (rants.length === index + 1) {
          // Add ref to last element
          return (
            <div key={rant._id} ref={lastRantRef}>
              <RantCard rant={rant} />
            </div>
          );
        } else {
          return <RantCard key={rant._id} rant={rant} />;
        }
      })}
    </div>
  );
};

export default RantList; 