import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { getUploadUrl } from '../utils/helpers';
import { useAuth } from '../context/AuthContext';
import api from '../utils/axiosConfig';
import { toast } from 'react-hot-toast';
import LoadingSpinner from './LoadingSpinner';
import {
  XMarkIcon,
  ChatBubbleLeftIcon,
  UserPlusIcon,
  UserMinusIcon,
  UserIcon
} from '@heroicons/react/24/outline';

const UserModal = ({ userId, onClose }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMate, setIsMate] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const { user: currentUser } = useAuth();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        setError(null);
        const userResponse = await api.get(`/users/${userId}`);
        setUser(userResponse.data);
        
        // Only fetch mate status if user is logged in
        if (currentUser) {
          const mateResponse = await api.get(`/mates/status/${userId}`);
          setIsMate(mateResponse.data.isMate);
          setRequestPending(mateResponse.data.isPending);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        setError(error.response?.data?.message || 'Failed to load user profile');
        toast.error('Failed to load user profile');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchUser();
    }
  }, [userId, currentUser]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleProfileClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
    navigate(`/profile/${user.username}`);
  };

  const handleToggleMate = async () => {
    if (!currentUser) {
      toast.error('Please log in to add mates');
      return;
    }

    if (isMate || requestPending) {
      return;
    }
    
    try {
      await api.post(`/mates/request/${userId}`);
      setRequestPending(true);
      toast.success('Mate request sent');
    } catch (error) {
      console.error('Error sending mate request:', error);
      toast.error(error.response?.data?.message || 'Failed to send mate request');
    }
  };

  const handleSendMessage = () => {
    if (!currentUser) {
      toast.error('Please log in to send messages');
      return;
    }
    
    navigate(`/messages/${userId}`);
    onClose();
  };

  const isOwnProfile = currentUser?._id === userId;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
      onClick={handleOverlayClick}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, scale: 0.95, y: 20 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.95, y: 20 }}
          className="fixed inset-x-4 top-[10%] md:inset-auto md:top-[15%] md:left-1/2 md:-translate-x-1/2 md:w-[28rem] bg-white rounded-2xl shadow-2xl overflow-hidden"
        >
          {loading ? (
            <div className="h-64 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : error ? (
            <div className="h-64 flex flex-col items-center justify-center p-4">
              <XMarkIcon className="w-12 h-12 text-red-500 mb-4" />
              <p className="text-gray-500 text-center">{error}</p>
              <button
                onClick={onClose}
                className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 transition-colors duration-200"
              >
                Close
              </button>
            </div>
          ) : user ? (
            <>
              {/* Header with background gradient */}
              <div className="relative h-32 bg-gradient-to-br from-blue-500 via-indigo-500 to-purple-500">
                <button
                  onClick={onClose}
                  className="absolute top-4 right-4 p-2 text-white hover:bg-white/20 rounded-full transition-colors duration-200"
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
              </div>

              {/* Profile Content */}
              <div className="px-6 pb-6">
                {/* Profile Image */}
                <div className="relative -mt-16 mb-4">
                  {user.profileImage ? (
                    <img
                      src={getUploadUrl(user.profileImage)}
                      alt={user.username}
                      className="w-32 h-32 rounded-full border-4 border-white object-cover shadow-lg mx-auto"
                    />
                  ) : (
                    <div className="w-32 h-32 rounded-full border-4 border-white shadow-lg mx-auto bg-gradient-to-br from-blue-500 to-purple-500 flex items-center justify-center">
                      <span className="text-4xl text-white font-bold">
                        {user.username[0].toUpperCase()}
                      </span>
                    </div>
                  )}
                </div>

                {/* User Info */}
                <div className="text-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-900">{user.username}</h2>
                  {user.bio && (
                    <p className="mt-2 text-gray-600">{user.bio}</p>
                  )}
                </div>

                {/* Stats */}
                <div className="grid grid-cols-3 gap-4 mb-6">
                  <div className="text-center p-3 bg-gray-50 rounded-xl">
                    <div className="text-2xl font-bold text-gray-900">{user.rantCount || 0}</div>
                    <div className="text-sm text-gray-500">Rants</div>
                  </div>
                  <div className="text-center p-3 bg-gray-50 rounded-xl">
                    <div className="text-2xl font-bold text-gray-900">{user.matesCount || 0}</div>
                    <div className="text-sm text-gray-500">Mates</div>
                  </div>
                  <div className="text-center p-3 bg-gray-50 rounded-xl">
                    <div className="text-2xl font-bold text-gray-900">{user.likesReceived || 0}</div>
                    <div className="text-sm text-gray-500">Likes</div>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex flex-col gap-3">
                  <button
                    onClick={handleProfileClick}
                    className="w-full px-4 py-2.5 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-xl font-medium hover:from-blue-600 hover:to-purple-700 transition-all duration-200 shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
                  >
                    View Full Profile
                  </button>
                  {currentUser && currentUser._id !== user._id && (
                    <button
                      onClick={handleToggleMate}
                      disabled={isMate || requestPending}
                      className={`w-full px-4 py-2.5 rounded-xl font-medium transition-all duration-200 ${
                        isMate
                          ? 'bg-gray-100 text-gray-700 cursor-not-allowed'
                          : requestPending
                          ? 'bg-yellow-50 text-yellow-700 cursor-not-allowed'
                          : 'bg-white text-blue-600 border-2 border-blue-600 hover:bg-blue-50'
                      }`}
                    >
                      {isMate ? 'Mates' : requestPending ? 'Request Pending' : 'Add Mate'}
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="h-64 flex flex-col items-center justify-center p-4">
              <XMarkIcon className="w-12 h-12 text-red-500 mb-4" />
              <p className="text-gray-500">User not found</p>
              <button
                onClick={onClose}
                className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 transition-colors duration-200"
              >
                Close
              </button>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default UserModal; 