import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../context/AuthContext';
import api from '../utils/axiosConfig';
import { toast } from 'react-hot-toast';

const reportReasons = [
  { id: 'hate_speech', label: 'Hate Speech', description: 'Content that promotes hate or discrimination' },
  { id: 'harassment', label: 'Harassment', description: 'Bullying, threats, or targeted harassment' },
  { id: 'violence', label: 'Violence', description: 'Content promoting or glorifying violence' },
  { id: 'spam', label: 'Spam', description: 'Repetitive, irrelevant, or promotional content' },
  { id: 'misinformation', label: 'Misinformation', description: 'False or misleading information' },
  { id: 'inappropriate', label: 'Inappropriate Content', description: 'NSFW, offensive, or inappropriate content' },
  { id: 'copyright', label: 'Copyright Violation', description: 'Content that violates intellectual property rights' },
  { id: 'impersonation', label: 'Impersonation', description: 'Account pretending to be someone else' },
  { id: 'other', label: 'Other', description: 'Other issues not listed above' }
];

function ReportModal({ isOpen, onClose, rantId, onSuccess }) {
  const { isAuthenticated } = useAuth();
  const [selectedReason, setSelectedReason] = useState('');
  const [details, setDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      setError('You must be logged in to report content');
      return;
    }

    if (!selectedReason) {
      setError('Please select a reason for reporting');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await api.post(`/rants/${rantId}/report`, {
        reason: selectedReason,
        details: details || '',
        contentType: 'rant',
        content: rantId
      });
      
      if (response.data) {
        toast.success('Report submitted successfully');
        onSuccess?.();
        onClose();
      }
    } catch (error) {
      console.error('Error submitting report:', error);
      const errorMessage = error.response?.data?.message || 'Failed to submit report. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage);
      
      if (error.response) {
        console.error('Error details:', {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers,
          request: {
            url: error.config.url,
            method: error.config.method,
            data: error.config.data
          }
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex items-center justify-between mb-6">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex items-center"
                  >
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-500 mr-2" />
                    Report Content
                  </Dialog.Title>
                  <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>

                {error && (
                  <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg">
                    <p className="text-sm text-red-600">{error}</p>
                  </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Reason for reporting
                    </label>
                    <select
                      value={selectedReason}
                      onChange={(e) => setSelectedReason(e.target.value)}
                      className="w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    >
                      <option value="">Select a reason</option>
                      {reportReasons.map((reason) => (
                        <option key={reason.id} value={reason.id}>
                          {reason.label}
                        </option>
                      ))}
                    </select>
                    {selectedReason && (
                      <p className="mt-1 text-sm text-gray-500">
                        {reportReasons.find(r => r.id === selectedReason)?.description}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Additional details (optional)
                    </label>
                    <textarea
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                      className="w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      rows="3"
                      maxLength={500}
                      placeholder="Please provide any additional context..."
                    />
                    <p className="mt-1 text-sm text-gray-500 text-right">
                      {details.length}/500
                    </p>
                  </div>

                  <div className="mt-6 flex justify-end space-x-3">
                    <button
                      type="button"
                      onClick={onClose}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={loading || !selectedReason}
                      className={`px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 ${
                        loading || !selectedReason ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      {loading ? 'Submitting...' : 'Submit Report'}
                    </button>
                  </div>
                </form>

                <div className="mt-6 border-t pt-4">
                  <p className="text-sm text-gray-500">
                    Thank you for helping keep our community safe. We'll review this report and take appropriate action.
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ReportModal; 