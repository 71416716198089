import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import api from '../utils/axiosConfig';

const RantContext = createContext();

export const useRants = () => {
  const context = useContext(RantContext);
  if (!context) {
    throw new Error('useRants must be used within a RantProvider');
  }
  return context;
};

export const RantProvider = ({ children }) => {
  const [rants, setRants] = useState([]);
  const [trendingRants, setTrendingRants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [sortBy, setSortBy] = useState('trending');
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    totalRants: 0
  });

  const retryWithBackoff = useCallback(async (fn, maxRetries = 3, baseDelay = 1000) => {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        return await fn();
      } catch (error) {
        retries++;
        if (retries === maxRetries) {
          throw error;
        }
        const delay = baseDelay * Math.pow(2, retries - 1) + Math.random() * 1000;
        console.log(`Retry ${retries} after ${delay}ms`);
        await new Promise(resolve => setTimeout(resolve, delay));
      }
    }
  }, []);

  const handleVoteUpdate = useCallback((updatedRant) => {
    setRants(prev => prev.map(rant => 
      rant._id === updatedRant._id 
        ? { 
            ...rant, 
            likes: updatedRant.likes || rant.likes,
            dislikes: updatedRant.dislikes || rant.dislikes,
            voteCount: (updatedRant.likes || []).length - (updatedRant.dislikes || []).length
          } 
        : rant
    ));
  }, []);

  const fetchRants = useCallback(async (params = {}) => {
    const {
      sort = sortBy,
      category = selectedCategory,
      cursor = null,
      limit = 10,
      page = 1,
      concurrent = false,
      append = false
    } = params;

    if (!concurrent) setLoading(true);
    try {
      let url = `/rants?limit=${limit}&page=${page}`;
      if (cursor) url += `&cursor=${cursor}`;
      if (category && category !== 'All') url += `&category=${category}`;
      
      // Handle different feed types
      switch (sort) {
        case 'following':
          url = '/rants/following';
          break;
        case 'mates':
          url = '/rants/mates';
          break;
        case 'trending':
          url += '&sort=trending';
          break;
        case 'latest':
          url += '&sort=-createdAt';
          break;
        default:
          url += '&sort=-createdAt';
          break;
      }

      const response = await api.get(url);
      
      // Deduplicate rants before updating state
      const newRants = response.data.rants || [];
      
      if (append) {
        if (sort === 'trending') {
          setTrendingRants(prev => {
            const existingIds = new Set(prev.map(r => r._id));
            const uniqueNewRants = newRants.filter(r => !existingIds.has(r._id));
            return [...prev, ...uniqueNewRants];
          });
        } else {
          setRants(prev => {
            const existingIds = new Set(prev.map(r => r._id));
            const uniqueNewRants = newRants.filter(r => !existingIds.has(r._id));
            return [...prev, ...uniqueNewRants];
          });
        }
      } else {
        if (sort === 'trending') {
          setTrendingRants(newRants);
        } else {
          setRants(newRants);
        }
      }

      return {
        hasMore: response.data.hasMore,
        nextCursor: response.data.nextCursor
      };
    } catch (error) {
      console.error('Error fetching rants:', error);
      setError(error.response?.data?.message || 'Failed to load rants');
      return { hasMore: false };
    } finally {
      if (!concurrent) setLoading(false);
    }
  }, [sortBy, selectedCategory]);

  // Fetch both latest and trending rants when needed
  useEffect(() => {
    const fetchAllRants = async () => {
      setLoading(true);
      try {
        // Fetch both latest and trending concurrently
        await Promise.all([
          fetchRants({ sort: 'latest', concurrent: true }),
          fetchRants({ sort: 'trending', concurrent: true })
        ]);
      } catch (error) {
        console.error('Error fetching all rants:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllRants();
  }, [selectedCategory, fetchRants]);

  const fetchRantById = useCallback(async (id) => {
    try {
      setLoading(true);
      setError(null);

      const fetchData = async () => {
        const response = await api.get(`/rants/${id}`);
        return response.data;
      };

      const data = await retryWithBackoff(fetchData);
      return data;
    } catch (err) {
      console.error('Error fetching rant:', err);
      setError(
        err.response?.status === 429
          ? 'Too many requests. Please wait a moment and try again.'
          : 'Failed to fetch rant. Please try again later.'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  }, [retryWithBackoff]);

  const value = {
    rants,
    trendingRants,
    loading,
    error,
    selectedCategory,
    setSelectedCategory,
    sortBy,
    setSortBy,
    pagination,
    fetchRants,
    fetchRantById,
    handleVoteUpdate
  };

  return <RantContext.Provider value={value}>{children}</RantContext.Provider>;
};

export default RantContext; 