import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { PhotoIcon, VideoCameraIcon, XMarkIcon } from '@heroicons/react/24/outline';
import api from '../utils/axiosConfig';
import { useAuth } from '../context/AuthContext';
import { 
  processImageFile, 
  validateVideoFile, 
  createPreviewUrl, 
  revokePreviewUrl,
  MAX_VIDEO_SIZE,
  SUPPORTED_VIDEO_TYPES 
} from '../utils/mediaUtils';

// Predefined categories
const CATEGORIES = [
  { id: 'general', name: 'General', description: 'General discussions and topics' },
  { id: 'tech', name: 'Technology', description: 'Tech news, gadgets, and software' },
  { id: 'politics', name: 'Politics', description: 'Political discussions and news' },
  { id: 'sports', name: 'Sports', description: 'Sports news and discussions' },
  { id: 'entertainment', name: 'Entertainment', description: 'Movies, TV, music, and pop culture' },
  { id: 'gaming', name: 'Gaming', description: 'Video games and gaming culture' },
  { id: 'food', name: 'Food', description: 'Food, cooking, and restaurants' },
  { id: 'health', name: 'Health', description: 'Health, fitness, and wellbeing' }
];

const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/quicktime'];

const CreateRant = ({ onClose }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('general');
  const [media, setMedia] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingProgress, setProcessingProgress] = useState(0);
  const [processingMessage, setProcessingMessage] = useState('');
  const fileInputRef = useRef(null);

  const validateFile = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      toast.error('File is too large. Maximum size is 8MB');
      return false;
    }
    
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      toast.error('Invalid file type. Please upload an image (JPG, PNG, GIF) or video (MP4)');
      return false;
    }
    
    return true;
  };

  const handleMediaChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length + media.length > 4) {
      toast.error('Maximum 4 media items allowed');
      return;
    }

    setIsProcessing(true);
    const processedMedia = [];
    let errorOccurred = false;

    try {
      for (const file of files) {
        if (!validateFile(file)) {
          errorOccurred = true;
          break;
        }

        let result;
        if (file.type.startsWith('video/')) {
          const validationResult = validateVideoFile(file);
          if (!validationResult.success) {
            toast.error(validationResult.error);
            errorOccurred = true;
            break;
          }
          result = {
            success: true,
            file,
            type: 'video'
          };
        } else {
          result = await processImageFile(file);
          if (result.success) {
            result.type = 'image';
          }
        }

        if (result.success) {
          processedMedia.push({
            file: result.file,
            type: result.type,
            previewUrl: createPreviewUrl(result.file)
          });
        } else {
          toast.error(result.error);
          errorOccurred = true;
          break;
        }
      }

      if (!errorOccurred) {
        setMedia(prev => [...prev, ...processedMedia]);
      }
    } catch (error) {
      console.error('Error processing media:', error);
      toast.error('Failed to process media');
    } finally {
      setIsProcessing(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  // Cleanup preview URLs when component unmounts
  useEffect(() => {
    return () => {
      media.forEach(item => {
        if (item.previewUrl) {
          revokePreviewUrl(item.previewUrl);
        }
      });
    };
  }, [media]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content.trim() && media.length === 0) {
      toast.error('Please add some content or media');
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('content', content);
    formData.append('category', category);

    // Add media files to form data with correct field names
    media.forEach((item) => {
      if (item.type === 'video') {
        formData.append('video', item.file);
      } else {
        formData.append('images', item.file);
      }
    });

    try {
      const response = await api.post('/rants', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProcessingProgress(progress);
          setProcessingMessage(`Uploading media... ${progress}%`);
        }
      });

      toast.success('Rant posted successfully!');
      onClose();
      navigate(`/rant/${response.data._id}`);
    } catch (error) {
      console.error('Error creating rant:', error);
      toast.error(error.response?.data?.message || 'Failed to post rant');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="create-rant">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="relative">
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="What's on your mind?"
            maxLength={1000}
            className="w-full min-h-[150px] p-4 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent resize-none bg-white"
            autoFocus
          />
          <div className="absolute bottom-2 right-2">
            <span className="text-sm text-gray-500">{content.length}/1000</span>
          </div>
        </div>

        {/* Category Selection - Scrollable on mobile */}
        <div className="overflow-x-auto -mx-4 px-4 sm:mx-0 sm:px-0">
          <div className="flex sm:grid sm:grid-cols-4 gap-2 min-w-max sm:min-w-0">
            {CATEGORIES.map((cat) => (
              <button
                key={cat.id}
                type="button"
                onClick={() => setCategory(cat.id)}
                className={`flex-shrink-0 p-3 rounded-lg text-left transition-colors ${
                  category === cat.id
                    ? 'bg-purple-100 border border-purple-500'
                    : 'bg-gray-50 border border-transparent hover:bg-gray-100'
                }`}
              >
                <div className="font-medium text-sm whitespace-nowrap">{cat.name}</div>
                <div className="text-xs text-gray-500 truncate max-w-[150px]">{cat.description}</div>
              </button>
            ))}
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            disabled={isSubmitting || media.length >= 4}
          >
            <PhotoIcon className="w-5 h-5 mr-2" />
            Add Media
          </button>
        </div>

        <input
          type="file"
          accept="image/*,video/*"
          multiple
          onChange={handleMediaChange}
          ref={fileInputRef}
          className="hidden"
        />

        {/* Media Preview Grid */}
        {media.length > 0 && (
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {media.map((item, index) => (
              <div key={index} className={`relative ${
                item.type === 'video' ? 'aspect-video' : 'aspect-square'
              }`}>
                {item.type === 'video' ? (
                  <video
                    src={item.previewUrl}
                    className="w-full h-full object-cover rounded-lg"
                    controls
                  />
                ) : (
                  <img
                    src={item.previewUrl}
                    alt={`Preview ${index + 1}`}
                    className="w-full h-full object-cover rounded-lg"
                  />
                )}
                <button
                  type="button"
                  onClick={() => {
                    revokePreviewUrl(item.previewUrl);
                    setMedia(media.filter((_, i) => i !== index));
                  }}
                  className="absolute top-2 right-2 p-1.5 bg-red-500 text-white rounded-full hover:bg-red-600 shadow-lg transform hover:scale-110 transition-all duration-200"
                >
                  <XMarkIcon className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="flex items-center space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-gray-700 hover:text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting || !content.trim() || isProcessing}
            className="px-6 py-2 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-lg hover:from-purple-700 hover:to-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 transform hover:scale-105 active:scale-95"
          >
            {isSubmitting ? 'Posting...' : 'Post Rant'}
          </button>
        </div>

        {/* Processing Status */}
        {isProcessing && (
          <div className="mt-4">
            <div className="flex items-center justify-between mb-1">
              <span className="text-sm font-medium text-gray-700">{processingMessage}</span>
              <span className="text-sm font-medium text-gray-700">{processingProgress}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-purple-600 h-2.5 rounded-full transition-all duration-300"
                style={{ width: `${processingProgress}%` }}
              ></div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateRant; 