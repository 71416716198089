import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useWebSocket } from '../context/WebSocketContext';
import { useMessages } from '../context/MessageContext';
import api from '../utils/axiosConfig';
import { toast } from 'react-hot-toast';
import { getUploadUrl } from '../utils/helpers';
import { formatDistanceToNow } from 'date-fns';
import { FaSearch, FaEllipsisV, FaPaperPlane, FaSmile, FaPlus } from 'react-icons/fa';
import Navbar from '../components/Navbar';
import UserSearch from '../components/UserSearch';
import EmojiPicker from 'emoji-picker-react';
import { Menu } from '@headlessui/react';

const Messages = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isAuthenticated } = useAuth();
  const { sendMessage: sendWebSocketMessage, isConnected } = useWebSocket();
  const { 
    messages: contextMessages = [],
    setMessages,
    unreadCount,
    conversations,
    setConversations,
    fetchMessages: fetchContextMessages,
    fetchConversations,
    clearMessages,
    loading: contextLoading
  } = useMessages();

  const [recipient, setRecipient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [recentChats, setRecentChats] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(!userId);
  const [showUserSearch, setShowUserSearch] = useState(false);
  const [filteredChats, setFilteredChats] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showConfirmRemoveMate, setShowConfirmRemoveMate] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [avatarErrors, setAvatarErrors] = useState({});
  const [messageAvatarErrors, setMessageAvatarErrors] = useState({});
  
  const messagesEndRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const messageInputRef = useRef(null);
  const loadingRef = useRef(false);
  const isMobile = window.innerWidth <= 768;
  const requestCache = useRef(new Map());
  const requestTimeouts = useRef(new Map());
  const abortController = useRef(new AbortController());

  // Computed values for search results display
  const showSearchResults = searchQuery && searchResults.length > 0;
  const chatsToDisplay = searchQuery && !showSearchResults ? filteredChats : recentChats;

  // Helper to determine if a message was sent by the current user
  const isOwnMessage = useCallback((message) => {
    return message.sender._id === user?._id;
  }, [user?._id]);

  // Fetch recent chats
  const fetchRecentChats = useCallback(async () => {
    try {
      const response = await api.get('/messages/recent');
      setRecentChats(response.data || []);
    } catch (error) {
      console.error('Error fetching recent chats:', error);
      toast.error('Failed to load recent chats');
    }
  }, []);

  // Handle navigation back to messages list
  const handleBackToList = useCallback(() => {
    clearMessages();
    setRecipient(null);
    setIsSidebarOpen(true);
    navigate('/messages');
  }, [clearMessages, navigate]);

  // Cache helper function
  const getCachedData = useCallback((key) => {
    const cached = requestCache.current.get(key);
    if (cached && Date.now() - cached.timestamp < 5000) { // 5 second cache
      return cached.data;
    }
    return null;
  }, []);

  // Fetch messages for a specific user
  const fetchMessages = useCallback(async () => {
    if (!userId || !isAuthenticated || !user || loadingRef.current) {
      setIsLoading(false);
      return;
    }

    const cacheKey = `messages-${userId}`;
    const cached = requestCache.current.get(cacheKey);
    if (cached && Date.now() - cached.timestamp < 5000) {
      setRecipient(cached.recipient);
      return;
    }

    try {
      loadingRef.current = true;
      setIsLoading(true);

      // Get recipient data
      const recipientRes = await api.get(`/users/${userId}`);
      if (!recipientRes.data) {
        toast.error('User not found');
        handleBackToList();
        return;
      }

      // Cache the recipient data
      requestCache.current.set(cacheKey, {
        timestamp: Date.now(),
        recipient: recipientRes.data
      });

      setRecipient(recipientRes.data);
      await fetchContextMessages(userId);

    } catch (error) {
      if (!error.message.includes('canceled')) {
        console.error('Error fetching messages:', error);
        toast.error('Failed to load messages');
      }
    } finally {
      setIsLoading(false);
      loadingRef.current = false;
    }
  }, [userId, isAuthenticated, user, handleBackToList, fetchContextMessages]);

  // Single effect for initialization
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: location } });
      return;
    }

    if (userId) {
      if (userId === user?._id) {
        handleBackToList();
        toast.error('You cannot message yourself');
        return;
      }
      
      fetchMessages();
    } else {
      fetchRecentChats();
    }

    return () => {
      abortController.current.abort();
    };
  }, [isAuthenticated, userId, user?._id, navigate, location, handleBackToList, fetchMessages, fetchRecentChats]);

  // Remove the periodic conversation update effect
  // Instead, update conversations only when needed
  const updateConversations = useCallback(() => {
    if (isAuthenticated && userId) {
      fetchConversations();
    }
  }, [isAuthenticated, userId, fetchConversations]);

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'instant' });
    }
  }, []);

  // Add effect to scroll to bottom when messages change
  useEffect(() => {
    if (!isLoading && contextMessages.length > 0) {
      scrollToBottom();
    }
  }, [isLoading, contextMessages.length, scrollToBottom]);

  // Handle typing indicator
  const handleTyping = useCallback(() => {
    if (!isConnected || !recipient?._id) return;

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    if (!typingTimeoutRef.current) {
      sendWebSocketMessage('typing', { recipientId: recipient._id });
    }

    typingTimeoutRef.current = setTimeout(() => {
      typingTimeoutRef.current = null;
    }, 2000);
  }, [isConnected, recipient, sendWebSocketMessage]);

  // Handle sending messages
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !recipient?._id || !isAuthenticated) return;

    const messageContent = newMessage.trim();
    setNewMessage('');

    // Create a temporary message object for immediate display
    const tempMessage = {
      _id: `temp_${Date.now()}`,
      content: messageContent,
      createdAt: new Date().toISOString(),
      sender: {
        _id: user._id,
        username: user.username,
        profileImage: user.profileImage
      },
      recipient: {
        _id: recipient._id,
        username: recipient.username,
        profileImage: recipient.profileImage
      },
      read: false
    };

    try {
      // Add temporary message to UI
      setMessages(prev => [...prev, tempMessage].sort((a, b) => 
        new Date(a.createdAt) - new Date(b.createdAt)
      ));

      // Scroll to bottom
      setTimeout(scrollToBottom, 100);

      // Send message via WebSocket
      sendWebSocketMessage('message', {
        recipientId: recipient._id,
        content: messageContent
      });

    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
      setNewMessage(messageContent);
      // Remove temporary message
      setMessages(prev => prev.filter(m => m._id !== tempMessage._id));
    }
  };

  // Message grouping helper
  const shouldShowAvatar = useCallback((message, index, allMessages) => {
    if (index === 0) return true;
    const prevMessage = allMessages[index - 1];
    if (!prevMessage) return true;
    
    // Show avatar if different sender or more than 5 minutes between messages
    const timeDiff = new Date(message.createdAt) - new Date(prevMessage.createdAt);
    const isNewSender = prevMessage.sender._id !== message.sender._id;
    const isTimeGap = timeDiff > 5 * 60 * 1000; // 5 minutes
    
    return isNewSender || isTimeGap;
  }, []);

  // Format message date
  const formatMessageDate = (dateString) => {
    if (!dateString) return '';
    try {
      return formatDistanceToNow(new Date(dateString), { addSuffix: true });
    } catch (error) {
      return '';
    }
  };

  // Message bubble component
  const MessageBubble = useCallback(({ message, showAvatar, isLastInGroup }) => {
    const isOwnMessage = message.sender._id === user?._id;
    const hasError = messageAvatarErrors[message.sender._id];
    
    const handleImageError = () => {
      setMessageAvatarErrors(prev => ({
        ...prev,
        [message.sender._id]: true
      }));
    };
    
    return (
      <div className={`flex items-end space-x-2 ${isOwnMessage ? 'justify-end' : 'justify-start'} mb-1`}>
        {/* Only show avatar for other person's messages */}
        {!isOwnMessage && (
          <div className="w-8 h-8 rounded-full overflow-hidden flex-shrink-0">
            {message.sender.profileImage && !hasError ? (
              <img
                src={getUploadUrl(message.sender.profileImage)}
                alt={message.sender.username}
                className="w-full h-full object-cover"
                onError={handleImageError}
              />
            ) : (
              <div className="w-full h-full bg-gradient-to-r from-purple-500 to-blue-500 flex items-center justify-center">
                <span className="text-white text-sm">{message.sender.username[0].toUpperCase()}</span>
              </div>
            )}
          </div>
        )}
        <div
          className={`max-w-[70%] px-4 py-2 rounded-lg shadow-sm ${
            isOwnMessage 
              ? 'bg-indigo-500 text-white ml-auto' 
              : 'bg-white/90 backdrop-blur-sm border border-gray-100'
          }`}
        >
          <p className="text-sm">{message.content}</p>
          <div className={`flex items-center justify-end mt-1 space-x-1 text-xs ${
            isOwnMessage 
              ? 'text-gray-300'
              : 'text-gray-400'
          }`}>
            <span>{formatMessageDate(message.createdAt)}</span>
            {isOwnMessage && (
              <span className={`ml-1 ${message.read ? 'text-white' : 'text-gray-400'}`}>
                {message.read ? '✓✓' : message.delivered ? '✓✓' : '✓'}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }, [user?._id, messageAvatarErrors]);

  // Initialize sidebar state
  useEffect(() => {
    setIsSidebarOpen(!userId);
  }, [userId]);

  // Render chat header
  const renderChatHeader = () => {
    if (!recipient) return null;

    return (
      <div className="flex items-center justify-between px-4 py-3 bg-white/80 backdrop-blur-sm border-b border-gray-200 shadow-sm">
        <div className="flex items-center space-x-3">
          {isMobile && (
            <button
              onClick={handleBackToList}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
          )}
          <Link to={`/profile/${recipient.username}`} className="flex items-center space-x-3">
            <div className="w-10 h-10 rounded-full overflow-hidden">
              {recipient.profileImage ? (
                <img
                  src={getUploadUrl(recipient.profileImage)}
                  alt={recipient.username}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-gradient-to-r from-purple-500 to-blue-500 flex items-center justify-center">
                  <span className="text-white">{recipient.username[0].toUpperCase()}</span>
                </div>
              )}
            </div>
            <div>
              <h2 className="font-medium text-gray-900">{recipient.username}</h2>
              {isTyping && (
                <p className="text-sm text-gray-500">typing...</p>
              )}
            </div>
          </Link>
        </div>
        <Menu as="div" className="relative">
          <Menu.Button className="p-2 hover:bg-gray-100 rounded-full">
            <FaEllipsisV className="w-5 h-5 text-gray-600" />
          </Menu.Button>
          <Menu.Items className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => navigate(`/profile/${recipient.username}`)}
                  className={`${
                    active ? 'bg-gray-100' : ''
                  } w-full text-left px-4 py-2 text-sm text-gray-700`}
                >
                  View Profile
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => setShowConfirmRemoveMate(true)}
                  className={`${
                    active ? 'bg-gray-100' : ''
                  } w-full text-left px-4 py-2 text-sm text-red-600`}
                >
                  Remove Mate
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    );
  };

  // Enhanced search function
  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (!query.trim()) {
      setSearchResults([]);
      setFilteredChats([]);
      return;
    }

    setIsSearching(true);
    try {
      // Search messages across all chats
      const response = await api.get(`/messages/search?q=${encodeURIComponent(query.trim())}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching messages:', error);
      toast.error('Failed to search messages');
    } finally {
      setIsSearching(false);
    }
  };

  // Handle clicking on a search result
  const handleSearchResultClick = (chatId, messageId) => {
    navigate(`/messages/${chatId}`);
    // TODO: Implement scrolling to specific message when chat loads
  };

  // Render sidebar with search results
  const renderSidebar = () => {
    const showSearchResults = searchQuery && searchResults.length > 0;
    const chatsToShow = searchQuery && !showSearchResults ? filteredChats : recentChats;
    
    return (
      <div className="flex flex-col h-full">
        {/* Search Header */}
        <div className="flex-shrink-0 border-b border-gray-200 bg-white z-20">
          <div className="flex items-center gap-2 p-3">
            <button
              onClick={() => navigate('/')}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search chats and messages..."
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                className="w-full pl-10 pr-4 py-2.5 bg-white/60 hover:bg-white focus:bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all"
              />
              <FaSearch className="absolute left-4 top-3.5 text-gray-400" />
            </div>
            <button
              onClick={() => {
                setShowUserSearch(true);
              }}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              aria-label="Start new chat"
            >
              <FaPlus className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        </div>

        {/* Chat List */}
        <div className="flex-1 overflow-y-auto">
          {isSearching ? (
            <div className="flex justify-center items-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          ) : showSearchResults ? (
            // Show search results
            <div>
              <div className="px-4 py-2 text-sm text-gray-500 bg-gray-50">
                Search results
              </div>
              {searchResults.map((result) => (
                <div
                  key={result.messageId}
                  onClick={() => handleSearchResultClick(result.chatId, result.messageId)}
                  className="p-4 hover:bg-gray-50 cursor-pointer border-b border-gray-100"
                >
                  <div className="flex items-center space-x-3">
                    <div className="w-10 h-10 rounded-full overflow-hidden">
                      {result.user.profileImage ? (
                        <img
                          src={getUploadUrl(result.user.profileImage)}
                          alt={result.user.username}
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <div className="w-full h-full bg-gradient-to-r from-purple-500 to-blue-500 flex items-center justify-center">
                          <span className="text-white">{result.user.username[0].toUpperCase()}</span>
                        </div>
                      )}
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="flex justify-between">
                        <h3 className="font-medium text-gray-900">{result.user.username}</h3>
                        <span className="text-xs text-gray-500">
                          {formatDistanceToNow(new Date(result.timestamp), { addSuffix: true })}
                        </span>
                      </div>
                      <p className="text-sm text-gray-500 line-clamp-2">
                        {result.messagePreview}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // Show regular chats list
            <>
              {chatsToDisplay.map((chat, index) => {
                const hasError = avatarErrors[chat.otherUser._id];
                
                const handleImageError = () => {
                  setAvatarErrors(prev => ({
                    ...prev,
                    [chat.otherUser._id]: true
                  }));
                };
                
                return (
                  <div
                    key={chat._id}
                    onClick={() => {
                      navigate(`/messages/${chat.otherUser._id}`);
                      if (isMobile) setIsSidebarOpen(false);
                    }}
                    className={`p-3 mx-2 my-1 hover:bg-white/80 cursor-pointer rounded-xl transition-all animate-fade-in ${
                      chat.otherUser._id === recipient?._id ? 'bg-white shadow-sm' : 'bg-white/40'
                    }`}
                    style={{
                      animationDelay: `${index * 50}ms`
                    }}
                  >
                    <div className="flex items-center space-x-3">
                      <div className="relative">
                        <div className="w-12 h-12 rounded-full overflow-hidden ring-2 ring-white/50 shadow-md">
                          {chat.otherUser.profileImage && !hasError ? (
                            <img
                              src={getUploadUrl(chat.otherUser.profileImage)}
                              alt={chat.otherUser.username}
                              className="w-full h-full object-cover"
                              onError={handleImageError}
                            />
                          ) : (
                            <div className="w-full h-full bg-gradient-to-r from-purple-500 to-blue-500 flex items-center justify-center">
                              <span className="text-white font-medium">{chat.otherUser.username[0].toUpperCase()}</span>
                            </div>
                          )}
                        </div>
                        {chat.unreadCount > 0 && (
                          <div className="absolute -top-1 -right-1 w-5 h-5 bg-indigo-500 ring-2 ring-white rounded-full flex items-center justify-center shadow-lg">
                            <span className="text-xs text-white font-medium">{chat.unreadCount}</span>
                          </div>
                        )}
                      </div>
                      <div className="flex-1 min-w-0">
                        <div className="flex justify-between items-center">
                          <h3 className="font-semibold text-gray-900 truncate">{chat.otherUser.username}</h3>
                          <span className="text-xs text-gray-500 font-medium">
                            {formatMessageDate(chat.lastMessage?.createdAt)}
                          </span>
                        </div>
                        <p className="text-sm text-gray-600 truncate mt-0.5">
                          {chat.lastMessage?.content || 'No messages yet'}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
              {chatsToDisplay.length === 0 && !searchQuery && (
                <div className="text-center py-12 px-4">
                  <div className="w-16 h-16 bg-gradient-to-br from-violet-500 to-fuchsia-500 rounded-full mx-auto mb-4 flex items-center justify-center">
                    <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>
                  </div>
                  <p className="text-gray-900 font-semibold mb-1">No conversations yet</p>
                  <p className="text-sm text-gray-500">Start chatting with your mates!</p>
                </div>
              )}
              {chatsToDisplay.length === 0 && searchQuery && (
                <div className="text-center py-12 px-4">
                  <div className="w-16 h-16 bg-gradient-to-br from-violet-500 to-fuchsia-500 rounded-full mx-auto mb-4 flex items-center justify-center">
                    <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                  </div>
                  <p className="text-gray-900 font-semibold mb-1">No results found</p>
                  <p className="text-sm text-gray-500">Try a different search term</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  // Add emoji to message
  const onEmojiClick = (emojiObject) => {
    setNewMessage(prev => prev + emojiObject.emoji);
    setShowEmojiPicker(false);
    messageInputRef.current?.focus();
  };

  // Add handler for removing mate
  const handleRemoveMate = async () => {
    try {
      await api.delete(`/mates/${recipient._id}`);
      toast.success(`Removed ${recipient.username} from mates`);
      navigate('/messages');
    } catch (error) {
      console.error('Error removing mate:', error);
      toast.error('Failed to remove mate');
    }
  };

  if (!isAuthenticated) return null;

  // Show empty state when no chat is selected
  if (!userId) {
    return (
      <>
        <Navbar />
        <div className="fixed inset-0 top-[64px] bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 flex flex-col">
          <div className="flex-1 flex overflow-hidden">
            {/* Sidebar */}
            <div className="w-full md:w-80 bg-white/80 backdrop-blur-sm border-r border-gray-200 flex-shrink-0 flex flex-col">
              {renderSidebar()}
            </div>

            {/* Empty state */}
            <div className="flex-1 flex items-center justify-center bg-gradient-to-br from-indigo-50/50 via-purple-50/50 to-pink-50/50">
              <div className="text-center">
                <div className="w-24 h-24 bg-gradient-to-br from-violet-500 to-fuchsia-500 rounded-full mx-auto mb-6 flex items-center justify-center">
                  <svg className="w-12 h-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Start a Conversation</h3>
                <p className="text-gray-600">Select a mate from the sidebar or click the plus button to begin chatting</p>
              </div>
            </div>
          </div>
        </div>

        {/* UserSearch modal with higher z-index */}
        <UserSearch 
          isOpen={showUserSearch} 
          onClose={() => setShowUserSearch(false)} 
        />
      </>
    );
  }

  if (isLoading && !recentChats?.length) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="fixed inset-0 top-[64px] bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 flex flex-col">
        <div className="flex-1 flex overflow-hidden">
          {/* Sidebar */}
          <div className={`
            w-full md:w-80 bg-white/80 backdrop-blur-sm border-r border-gray-200 flex-shrink-0 flex flex-col
            ${isMobile ? (isSidebarOpen || !userId ? 'fixed inset-x-0 bottom-0 top-[64px] z-30' : 'hidden') : 'block'}
          `}>
            {renderSidebar()}
          </div>

          {/* Chat Area */}
          <div className={`flex-1 flex flex-col min-h-0 ${isMobile && (!userId || isSidebarOpen) ? 'hidden' : 'block'}`}>
            <div className="flex-shrink-0 sticky top-0 z-10">
              {renderChatHeader()}
            </div>
            
            <div className="flex-1 overflow-y-auto p-4 space-y-3 min-h-0 bg-gradient-to-br from-indigo-50/50 via-purple-50/50 to-pink-50/50">
              {contextMessages && contextMessages.length > 0 ? (
                contextMessages.map((message, index) => {
                  if (!message) return null;
                  const showAvatar = shouldShowAvatar(message, index, contextMessages);
                  const isLastInGroup = index === contextMessages.length - 1 || shouldShowAvatar(contextMessages[index + 1], index + 1, contextMessages);
                  
                  return (
                    <MessageBubble
                      key={message._id}
                      message={message}
                      showAvatar={showAvatar}
                      isLastInGroup={isLastInGroup}
                    />
                  );
                })
              ) : (
                <div className="flex flex-col items-center justify-center h-full text-gray-500">
                  <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
                    <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>
                  </div>
                  <p className="text-center">No messages yet</p>
                  <p className="text-sm text-gray-400">Start a conversation!</p>
                </div>
              )}
              <div ref={messagesEndRef}></div>
            </div>
            
            {recipient && (
              <div className="flex-shrink-0 sticky bottom-0 p-4 bg-white border-t border-gray-200 shadow-lg">
                <form onSubmit={handleSendMessage} className="flex items-center space-x-2">
                  <div className="relative">
                    <button
                      type="button"
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      className="p-2 text-gray-500 hover:text-gray-600 transition-colors"
                    >
                      <FaSmile className="w-6 h-6" />
                    </button>
                    {showEmojiPicker && (
                      <div className="absolute bottom-12 left-0 z-50">
                        <EmojiPicker
                          onEmojiClick={onEmojiClick}
                          searchDisabled
                          skinTonesDisabled
                          width={300}
                          height={400}
                        />
                      </div>
                    )}
                  </div>
                  <input
                    ref={messageInputRef}
                    type="text"
                    value={newMessage}
                    onChange={(e) => {
                      setNewMessage(e.target.value);
                      handleTyping();
                    }}
                    placeholder="Type a message"
                    className="flex-1 px-4 py-2 bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                  />
                  <button
                    type="submit"
                    disabled={!newMessage.trim()}
                    className="p-2 text-blue-500 hover:text-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                  >
                    <FaPaperPlane className="w-6 h-6" />
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* UserSearch modal with higher z-index */}
      <UserSearch 
        isOpen={showUserSearch} 
        onClose={() => setShowUserSearch(false)} 
      />

      {showConfirmRemoveMate && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-sm mx-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Remove Mate</h3>
            <p className="text-gray-500 mb-6">
              Are you sure you want to remove {recipient?.username} from your mates? You will also stop following each other.
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowConfirmRemoveMate(false)}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg text-sm font-medium"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleRemoveMate();
                  setShowConfirmRemoveMate(false);
                }}
                className="px-4 py-2 bg-red-500 text-white hover:bg-red-600 rounded-lg text-sm font-medium"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Messages; 