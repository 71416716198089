import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useRants } from '../context/RantContext';
import RantList from '../components/RantList';
import CategorySelector from '../components/CategorySelector';
import CreateRant from '../components/CreateRant';
import NotificationBell from '../components/NotificationBell';
import FeedNavigation from '../components/FeedNavigation';
import UserSearch from '../components/UserSearch';
import { FaSearch } from 'react-icons/fa';
import { getUploadUrl } from '../utils/helpers';
import logo from '../logo.jpg';
import { motion, AnimatePresence } from 'framer-motion';

const Home = () => {
  const { isAuthenticated, user, logout } = useAuth();
  const { 
    rants = [], 
    trendingRants = [],
    fetchRants, 
    loading, 
    error 
  } = useRants();
  const [showCreateRant, setShowCreateRant] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [activeTab, setActiveTab] = useState('latest');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const navigate = useNavigate();

  const memoizedRants = useMemo(() => {
    const rantsToShow = activeTab === 'trending' 
      ? (Array.isArray(trendingRants) ? trendingRants : [])
      : (Array.isArray(rants) ? rants : []);

    return rantsToShow.map(rant => ({
      ...rant,
      isLiked: isAuthenticated && Array.isArray(rant.likes) && rant.likes.includes(user?._id),
      isDisliked: isAuthenticated && Array.isArray(rant.dislikes) && rant.dislikes.includes(user?._id)
    }));
  }, [rants, trendingRants, activeTab, isAuthenticated, user?._id]);

  // Initial fetch
  useEffect(() => {
    const fetchInitialFeed = async () => {
      try {
        setPage(1);
        let params = { page: 1, limit: 10 };
        
        switch (activeTab) {
          case 'following':
            params.sort = 'following';
            break;
          case 'mates':
            params.sort = 'mates';
            break;
          case 'trending':
            params.sort = 'trending';
            break;
          case 'latest':
          default:
            params.sort = 'latest';
            break;
        }

        const response = await fetchRants(params);
        setHasMore(response?.hasMore || false);
      } catch (error) {
        console.error('Error fetching feed:', error);
      }
    };

    fetchInitialFeed();
  }, [activeTab, fetchRants]);

  // Load more function for infinite scroll
  const loadMore = useCallback(async () => {
    if (loadingMore || !hasMore) return;

    try {
      setLoadingMore(true);
      const nextPage = page + 1;
      let params = { 
        page: nextPage, 
        limit: 10,
        append: true  // Add append flag
      };

      switch (activeTab) {
        case 'following':
          params.sort = 'following';
          break;
        case 'mates':
          params.sort = 'mates';
          break;
        case 'trending':
          params.sort = 'trending';
          break;
        case 'latest':
        default:
          params.sort = 'latest';
          break;
      }

      const response = await fetchRants(params);
      setHasMore(response?.hasMore || false);
      setPage(nextPage);
    } catch (error) {
      console.error('Error loading more rants:', error);
    } finally {
      setLoadingMore(false);
    }
  }, [loadingMore, hasMore, page]);

  // Intersection Observer for infinite scroll
  const observer = useRef();
  const lastRantElementRef = useCallback((node) => {
    if (loading || loadingMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, loadingMore, hasMore, loadMore]);

  const handleTabChange = (tab) => {
    if (!isAuthenticated && (tab === 'following' || tab === 'mates')) {
      navigate('/login');
      return;
    }
    setActiveTab(tab);
  };

  const SearchButton = () => (
    <button
      onClick={() => setShowSearch(true)}
      className="p-2 text-gray-600 hover:text-blue-500 transition-all duration-200 rounded-full hover:bg-blue-50"
    >
      <FaSearch className="w-5 h-5" />
    </button>
  );

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-white">
      {/* Mobile Header */}
      <div className="md:hidden fixed top-0 left-0 right-0 bg-gray-50 shadow-sm border-b border-purple-100 z-50">
        <div className="flex flex-col">
          <div className="flex items-center justify-between px-4 py-2">
            <Link to="/" className="flex items-center space-x-2">
              <img
                src="/favicon/web-app-manifest-192x192.png"
                alt="RantUK Logo"
                className="h-8 w-8"
              />
              <span className="text-xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
                RantUK
              </span>
            </Link>
            {isAuthenticated ? (
              <div className="flex items-center space-x-3">
                <SearchButton />
                <Link
                  to="/messages"
                  className="p-2 text-gray-600 hover:text-blue-500 transition-all duration-200 rounded-full hover:bg-blue-50"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                  </svg>
                </Link>
                <NotificationBell />
                <div className="relative">
                  <button
                    onClick={() => setShowProfileDropdown(!showProfileDropdown)}
                    className="w-10 h-10 rounded-full ring-2 ring-purple-500/30 overflow-hidden bg-gradient-to-r from-purple-500 to-blue-500 flex items-center justify-center"
                  >
                    {user?.profileImage ? (
                      <img
                        src={getUploadUrl(user.profileImage)}
                        alt={user.username}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="text-lg font-medium text-white">
                        {user?.username?.charAt(0)?.toUpperCase()}
                      </span>
                    )}
                  </button>

                  <AnimatePresence>
                    {showProfileDropdown && (
                      <>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-30"
                          onClick={() => setShowProfileDropdown(false)}
                        />
                        <motion.div
                          initial={{ opacity: 0, scale: 0.95, y: -20 }}
                          animate={{ opacity: 1, scale: 1, y: 0 }}
                          exit={{ opacity: 0, scale: 0.95, y: -20 }}
                          className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg z-40 border border-gray-100 overflow-hidden"
                        >
                          <div className="py-2">
                            <div className="px-4 py-3 border-b border-gray-100">
                              <p className="text-sm font-medium text-gray-900">{user.username}</p>
                              <p className="text-xs text-gray-500 mt-1">{user.email}</p>
                            </div>
                            <Link
                              to={`/profile/${user?.username}`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors"
                              onClick={() => setShowProfileDropdown(false)}
                            >
                              Profile
                            </Link>
                            {(user?.role === 'admin' || user?.role === 'superadmin') && (
                              <Link
                                to="/admin"
                                className="block px-4 py-2 text-sm text-purple-600 hover:bg-purple-50 transition-colors"
                                onClick={() => setShowProfileDropdown(false)}
                              >
                                Admin Dashboard
                              </Link>
                            )}
                            <Link
                              to="/settings"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors"
                              onClick={() => setShowProfileDropdown(false)}
                            >
                              Settings
                            </Link>
                            <button
                              onClick={handleLogout}
                              className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors"
                            >
                              Sign out
                            </button>
                          </div>
                        </motion.div>
                      </>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <Link
                  to="/login"
                  className="px-4 py-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-full text-sm font-medium shadow-lg"
                >
                  Login
                </Link>
              </div>
            )}
          </div>
          <div className="px-4 py-2">
            <FeedNavigation activeTab={activeTab} onTabChange={handleTabChange} />
          </div>
        </div>
      </div>

      {/* Desktop Header */}
      <div className="hidden md:block fixed top-0 left-0 right-0 bg-white shadow-sm border-b border-gray-200 z-50">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <Link to="/" className="flex items-center space-x-2">
              <img
                src="/favicon/web-app-manifest-192x192.png"
                alt="RantUK Logo"
                className="h-8 w-8"
              />
              <span className="text-xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
                RantUK
              </span>
            </Link>
            {isAuthenticated && (
              <div className="flex items-center space-x-3">
                <SearchButton />
                <Link
                  to="/messages"
                  className="p-2 text-gray-600 hover:text-blue-500 transition-all duration-200 rounded-full hover:bg-blue-50"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                  </svg>
                </Link>
                <NotificationBell />
                <div className="relative">
                  <button
                    onClick={() => setShowProfileDropdown(!showProfileDropdown)}
                    className="w-10 h-10 rounded-full ring-2 ring-purple-500/30 overflow-hidden bg-gradient-to-r from-purple-500 to-blue-500 flex items-center justify-center"
                  >
                    {user?.profileImage ? (
                      <img
                        src={getUploadUrl(user.profileImage)}
                        alt={user.username}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="text-lg font-medium text-white">
                        {user?.username?.charAt(0)?.toUpperCase()}
                      </span>
                    )}
                  </button>

                  <AnimatePresence>
                    {showProfileDropdown && (
                      <>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-30"
                          onClick={() => setShowProfileDropdown(false)}
                        />
                        <motion.div
                          initial={{ opacity: 0, scale: 0.95, y: -20 }}
                          animate={{ opacity: 1, scale: 1, y: 0 }}
                          exit={{ opacity: 0, scale: 0.95, y: -20 }}
                          className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg z-40 border border-gray-100 overflow-hidden"
                        >
                          <div className="py-2">
                            <div className="px-4 py-3 border-b border-gray-100">
                              <p className="text-sm font-medium text-gray-900">{user.username}</p>
                              <p className="text-xs text-gray-500 mt-1">{user.email}</p>
                            </div>
                            <Link
                              to={`/profile/${user?.username}`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors"
                              onClick={() => setShowProfileDropdown(false)}
                            >
                              Profile
                            </Link>
                            {(user?.role === 'admin' || user?.role === 'superadmin') && (
                              <Link
                                to="/admin"
                                className="block px-4 py-2 text-sm text-purple-600 hover:bg-purple-50 transition-colors"
                                onClick={() => setShowProfileDropdown(false)}
                              >
                                Admin Dashboard
                              </Link>
                            )}
                            <Link
                              to="/settings"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors"
                              onClick={() => setShowProfileDropdown(false)}
                            >
                              Settings
                            </Link>
                            <button
                              onClick={handleLogout}
                              className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors"
                            >
                              Sign out
                            </button>
                          </div>
                        </motion.div>
                      </>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Adjust top margin for header - update the padding values */}
      <div className="pt-28 md:pt-16">
        {/* Feed Navigation - Desktop Only */}
        <div className="hidden md:block px-4 py-0 md:py-0 md:px-0">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
              <div className="md:col-span-1">
                {/* Empty column to match grid */}
              </div>
              <div className="md:col-span-2">
                <div className="mb-2">
                  <FeedNavigation activeTab={activeTab} onTabChange={handleTabChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 md:py-6 pt-2">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
          {/* Left sidebar - Hide on mobile */}
          <div className="hidden md:block md:col-span-1">
            <div className="sticky top-8 space-y-6">
              <div className="bg-white rounded-2xl shadow-lg p-6">
                {isAuthenticated ? (
                  <>
                    <div className="flex flex-col items-center">
                      <div className="w-24 h-24 rounded-full ring-4 ring-purple-500/30 overflow-hidden bg-gradient-to-r from-purple-500 to-blue-500 flex items-center justify-center mb-4">
                        {user?.profileImage ? (
                          <img
                            src={getUploadUrl(user.profileImage)}
                            alt={user.username}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <span className="text-4xl font-medium text-white">
                            {user?.username?.charAt(0)?.toUpperCase()}
                          </span>
                        )}
                      </div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-1">
                        {user?.username}
                      </h3>
                      <p className="text-sm text-gray-500 mb-6">
                        {user?.bio || 'No bio yet'}
                      </p>
                      <button
                        onClick={() => setShowCreateRant(true)}
                        className="w-full bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-xl py-3 px-4 text-center font-medium shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-0.5"
                      >
                        Create Rant
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col items-center">
                    <div className="w-24 h-24 rounded-full overflow-hidden mb-6">
                      <img
                        src={logo}
                        alt="RantUK Logo"
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <Link
                      to="/login"
                      className="w-full bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-xl py-3 px-4 text-center font-medium shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-0.5 mb-3"
                    >
                      Login
                    </Link>
                    <Link
                      to="/register"
                      className="w-full bg-gray-100 text-gray-700 rounded-xl py-3 px-4 text-center font-medium hover:bg-gray-200 transition-all"
                    >
                      Register
                    </Link>
                  </div>
                )}
              </div>
              <div className="bg-white rounded-2xl shadow-lg p-6">
                <CategorySelector />
              </div>
            </div>
          </div>

          {/* Main content area */}
          <div className="md:col-span-2">
            {!isAuthenticated && (activeTab === 'following' || activeTab === 'mates') ? (
              <div className="bg-white rounded-xl p-8 text-center">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {activeTab === 'following' ? 'Follow users to see their rants' : 'Add mates to see their rants'}
                </h3>
                <p className="text-gray-600 mb-6">
                  {activeTab === 'following' 
                    ? 'Follow other users to see their rants in your feed.'
                    : 'Connect with other users as mates to see their rants here.'}
                </p>
                <Link
                  to="/login"
                  className="inline-block px-6 py-3 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-xl font-medium shadow-lg hover:shadow-xl transition-all"
                >
                  Login to Continue
                </Link>
              </div>
            ) : (
              <>
                <RantList 
                  rants={memoizedRants} 
                  loading={loading} 
                  error={error}
                  lastRantRef={lastRantElementRef}
                />
                {loadingMore && (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                  </div>
                )}
              </>
            )}
            
            {/* Mobile Create Rant FAB */}
            <div className="md:hidden fixed right-4 bottom-4">
              <button
                onClick={() => isAuthenticated ? setShowCreateRant(true) : navigate('/login')}
                className="w-14 h-14 rounded-full bg-gradient-to-r from-purple-500 to-blue-500 text-white shadow-lg flex items-center justify-center hover:shadow-xl transition-all transform hover:-translate-y-0.5"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Create Rant Modal */}
      {showCreateRant && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-2xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 rounded-t-2xl">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-gray-900">Create Rant</h2>
                <button
                  onClick={() => setShowCreateRant(false)}
                  className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100 transition-colors"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="p-6">
              <CreateRant onClose={() => setShowCreateRant(false)} />
            </div>
          </div>
        </div>
      )}

      {/* User Search Modal */}
      <UserSearch isOpen={showSearch} onClose={() => setShowSearch(false)} />
    </div>
  );
};

export default Home; 