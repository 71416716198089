import React from 'react';
import { Link } from 'react-router-dom';

export default function Privacy() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
          
          <div className="space-y-6 text-gray-600">
            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">1. Information We Collect</h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>Email address (for account creation and notifications)</li>
                <li>Username (chosen by you)</li>
                <li>Content you post on RantUK</li>
                <li>Usage data and analytics</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">2. How We Use Your Information</h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>To provide and maintain the service</li>
                <li>To notify you about changes to our service</li>
                <li>To provide customer support</li>
                <li>To detect and prevent abuse</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">3. Data Security</h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>We use industry-standard security measures</li>
                <li>Your password is securely hashed</li>
                <li>Data is encrypted in transit</li>
                <li>Regular security audits are performed</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">4. Your Rights</h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>Access your personal data</li>
                <li>Request correction of your data</li>
                <li>Request deletion of your data</li>
                <li>Object to processing of your data</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">5. Cookies</h2>
              <p>We use cookies to improve your experience and analyze usage patterns.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">6. Third-Party Services</h2>
              <p>We may use third-party services to help operate our service. These services have their own privacy policies.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">7. Changes to Privacy Policy</h2>
              <p>We may update this privacy policy. We will notify you of any changes by posting the new policy on this page.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">8. Contact Us</h2>
              <p>If you have any questions about this Privacy Policy, please contact us at privacy@rantuk.com</p>
            </section>
          </div>

          <div className="mt-8 pt-8 border-t border-gray-200">
            <Link to="/register" className="text-blue-600 hover:text-blue-800 font-medium">
              ← Back to Registration
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 