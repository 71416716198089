import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { toast } from 'react-hot-toast';

const WebSocketContext = createContext();

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const { user } = useAuth();
  const [isConnected, setIsConnected] = useState(false);
  const socket = useRef(null);
  const reconnectTimeout = useRef(null);
  const messageQueue = useRef([]);
  const reconnectAttempts = useRef(0);
  const MAX_RECONNECT_ATTEMPTS = 5;

  const getToken = useCallback(() => {
    return localStorage.getItem('auth_token');
  }, []);

  const processQueue = useCallback(() => {
    if (messageQueue.current.length > 0) {
      console.log(`Processing queue: ${messageQueue.current.length} messages`);
    }
    while (messageQueue.current.length > 0 && socket.current?.readyState === WebSocket.OPEN) {
      const { type, data } = messageQueue.current.shift();
      try {
        socket.current.send(JSON.stringify({ type, data }));
        console.log('Sent queued message:', { type, data });
      } catch (error) {
        console.error('Error sending queued message:', error);
        messageQueue.current.unshift({ type, data }); // Put message back at front of queue
        break;
      }
    }
  }, []);

  const connect = useCallback(() => {
    const token = getToken();
    if (!token) {
      console.log('No token in localStorage, skipping connection');
      return;
    }

    try {
      if (socket.current?.readyState === WebSocket.OPEN) {
        console.log('WebSocket already connected');
        return;
      }

      if (socket.current?.readyState === WebSocket.CONNECTING) {
        console.log('WebSocket already connecting');
        return;
      }

      // Close any existing socket before creating a new one
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }

      // Use the same base URL as the API, but for WebSocket
      const wsBase = process.env.REACT_APP_WS_URL || 'wss://rantuk.com/ws';
      const wsUrl = `${wsBase}?token=${encodeURIComponent(token)}`;
      
      console.log('Connecting to WebSocket:', wsUrl);
      socket.current = new WebSocket(wsUrl);

      socket.current.onopen = () => {
        console.log('WebSocket connected successfully');
        setIsConnected(true);
        reconnectAttempts.current = 0;
        if (reconnectTimeout.current) {
          clearTimeout(reconnectTimeout.current);
          reconnectTimeout.current = null;
        }
        processQueue();
      };

      socket.current.onclose = (event) => {
        console.log('WebSocket disconnected:', event.code, event.reason);
        setIsConnected(false);
        
        const token = getToken();
        if (token && reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
          const delay = Math.min(1000 * Math.pow(2, reconnectAttempts.current), 30000);
          console.log(`Scheduling reconnect attempt ${reconnectAttempts.current + 1} in ${delay}ms`);
          reconnectTimeout.current = setTimeout(() => {
            reconnectAttempts.current++;
            connect();
          }, delay);
        } else {
          console.log('Max reconnect attempts reached or no token');
        }
      };

      socket.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        setIsConnected(false);
      };

      socket.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received WebSocket message:', data);
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

    } catch (error) {
      console.error('Error creating WebSocket connection:', error);
      setIsConnected(false);
    }
  }, [getToken, processQueue]);

  useEffect(() => {
    console.log('WebSocket effect running, checking token');
    const token = getToken();
    if (token) {
      connect();
    } else {
      if (socket.current) {
        console.log('Closing existing socket due to no token');
        socket.current.close();
        socket.current = null;
      }
    }

    return () => {
      console.log('Cleaning up WebSocket connection');
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
        reconnectTimeout.current = null;
      }
    };
  }, [connect, getToken]);

  const sendMessage = useCallback((type, data) => {
    console.log('Attempting to send message:', { type, data });
    console.log('Socket state:', socket.current?.readyState);
    
    const token = getToken();
    if (!token) {
      console.log('No token in localStorage, cannot send message');
      return;
    }

    if (!socket.current || socket.current.readyState !== WebSocket.OPEN) {
      console.log('WebSocket not connected, queueing message');
      messageQueue.current.push({ type, data });
      if (!isConnected && !reconnectTimeout.current) {
        console.log('Triggering reconnect attempt');
        connect();
      }
      return;
    }

    try {
      const message = JSON.stringify({ type, data });
      console.log('Sending message:', message);
      socket.current.send(message);
    } catch (error) {
      console.error('Error sending message:', error);
      messageQueue.current.push({ type, data });
      if (!isConnected) {
        connect();
      }
    }
  }, [isConnected, connect, getToken]);

  return (
    <WebSocketContext.Provider value={{
      socket: socket.current,
      isConnected,
      sendMessage,
      connect
    }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContext; 