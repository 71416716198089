import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const ImageViewer = ({ image, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90"
      onClick={onClose}
    >
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-white p-2 rounded-full hover:bg-white/10 transition-colors"
      >
        <XMarkIcon className="w-6 h-6" />
      </button>

      <div className="relative max-w-[90vw] max-h-[90vh]" onClick={e => e.stopPropagation()}>
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-8 h-8 border-4 border-white/30 border-t-white rounded-full animate-spin"></div>
          </div>
        )}
        
        {error ? (
          <div className="text-white text-center p-4">
            Failed to load image
          </div>
        ) : (
          <img
            src={image}
            alt="Full size"
            className={`max-w-full max-h-[90vh] object-contain ${loading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-200`}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        )}
      </div>
    </div>
  );
};

export default ImageViewer; 