import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useWebSocket } from '../context/WebSocketContext';
import api from '../utils/axiosConfig';
import { toast } from 'react-hot-toast';
import { HandThumbUpIcon } from '@heroicons/react/24/outline';
import { HandThumbUpIcon as HandThumbUpIconSolid } from '@heroicons/react/24/solid';
import { formatDistanceToNow } from 'date-fns';
import UserModal from './UserModal';

const CommentSection = ({ rantId, initialComments = [] }) => {
  const [comments, setComments] = useState(initialComments);
  const [newComment, setNewComment] = useState('');
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { isAuthenticated, user } = useAuth();
  const { socket } = useWebSocket();

  useEffect(() => {
    if (socket) {
      const handleMessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          
          if (data.type === 'newComment' && data.comment.rant === rantId) {
            setComments(prev => [data.comment, ...prev]);
          } else if (data.type === 'commentLiked') {
            const { commentId, likes } = data;
            setComments(prev => prev.map(comment => 
              comment._id === commentId ? { ...comment, likes } : comment
            ));
          }
        } catch (error) {
          console.error('Error handling WebSocket message:', error);
        }
      };

      socket.addEventListener('message', handleMessage);

      return () => {
        socket.removeEventListener('message', handleMessage);
      };
    }
  }, [socket, rantId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      const response = await api.post(`/rants/${rantId}/comments`, {
        content: newComment.trim()
      });
      setComments(prev => [response.data, ...prev]);
      setNewComment('');
    } catch (error) {
      console.error('Error posting comment:', error);
      toast.error('Failed to post comment');
    }
  };

  const handleLike = async (commentId) => {
    if (!isAuthenticated) {
      toast.error('Please login to like comments');
      return;
    }

    try {
      const response = await api.post(`/rants/${rantId}/comments/${commentId}/like`);
      setComments(prev => prev.map(comment => 
        comment._id === commentId ? { ...comment, likes: response.data.likes } : comment
      ));
    } catch (error) {
      console.error('Error liking comment:', error);
      toast.error('Failed to like comment');
    }
  };

  const handleUserClick = (userId) => {
    setSelectedUserId(userId);
    setShowUserModal(true);
  };

  return (
    <div className="space-y-6">
      {isAuthenticated && (
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            rows="3"
          />
          <button
            type="submit"
            disabled={!newComment.trim()}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Post Comment
          </button>
        </form>
      )}

      <div className="space-y-4">
        {comments.map((comment) => (
          <div key={comment._id} className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <button
                  onClick={() => handleUserClick(comment.user._id)}
                  className="font-semibold text-blue-600 hover:text-blue-800"
                >
                  {comment.user.username}
                </button>
                <span className="text-gray-500 text-sm ml-2">
                  {formatDistanceToNow(new Date(comment.createdAt), { addSuffix: true })}
                </span>
              </div>
              <button
                onClick={() => handleLike(comment._id)}
                className={`flex items-center space-x-1 ${
                  comment.likes?.includes(user?._id)
                    ? 'text-blue-500'
                    : 'text-gray-500 hover:text-blue-500'
                }`}
              >
                {comment.likes?.includes(user?._id) ? (
                  <HandThumbUpIconSolid className="w-5 h-5" />
                ) : (
                  <HandThumbUpIcon className="w-5 h-5" />
                )}
                <span>{comment.likes?.length || 0}</span>
              </button>
            </div>
            <p className="text-gray-700">{comment.content}</p>
          </div>
        ))}
      </div>

      {showUserModal && (
        <UserModal
          userId={selectedUserId}
          onClose={() => setShowUserModal(false)}
        />
      )}
    </div>
  );
};

export default CommentSection; 