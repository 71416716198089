import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { FaUserPlus, FaCheck, FaTimes, FaSearch } from 'react-icons/fa';
import api from '../utils/axiosConfig';
import { getUploadUrl } from '../utils/helpers';

const Mates = ({ activeTab: initialActiveTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(initialActiveTab || 'mates');
  const [mates, setMates] = useState([]);
  const [mateRequests, setMateRequests] = useState([]);
  const [sentRequests, setSentRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch mates list
  const fetchMates = async () => {
    try {
      const response = await api.get('/mates');
      setMates(response.data);
    } catch (error) {
      console.error('Error fetching mates:', error);
      toast.error('Failed to load mates');
    }
  };

  // Fetch mate requests
  const fetchMateRequests = async () => {
    try {
      const response = await api.get('/mates/requests');
      setMateRequests(response.data);
    } catch (error) {
      console.error('Error fetching mate requests:', error);
      toast.error('Failed to load mate requests');
    }
  };

  // Fetch sent requests
  const fetchSentRequests = async () => {
    try {
      const response = await api.get('/mates/sent-requests');
      setSentRequests(response.data);
    } catch (error) {
      console.error('Error fetching sent requests:', error);
      toast.error('Failed to load sent requests');
    }
  };

  // Handle search
  const handleSearch = async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    try {
      setIsSearching(true);
      const response = await api.get(`/users/search?q=${encodeURIComponent(query)}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching users:', error);
      toast.error('Failed to search users');
    } finally {
      setIsSearching(false);
    }
  };

  // Handle mate request actions
  const handleAcceptRequest = async (requestId) => {
    try {
      await api.post(`/mates/accept/${requestId}`);
      toast.success('Mate request accepted');
      fetchMateRequests();
      fetchMates();
    } catch (error) {
      console.error('Error accepting mate request:', error);
      toast.error('Failed to accept mate request');
    }
  };

  const handleRejectRequest = async (requestId) => {
    try {
      await api.post(`/mates/reject/${requestId}`);
      toast.success('Mate request rejected');
      fetchMateRequests();
    } catch (error) {
      console.error('Error rejecting mate request:', error);
      toast.error('Failed to reject mate request');
    }
  };

  const handleCancelRequest = async (requestId) => {
    try {
      await api.post(`/mates/cancel/${requestId}`);
      toast.success('Request cancelled');
      fetchSentRequests();
    } catch (error) {
      console.error('Error cancelling request:', error);
      toast.error('Failed to cancel request');
    }
  };

  const handleSendRequest = async (userId) => {
    try {
      await api.post(`/mates/request/${userId}`);
      toast.success('Mate request sent');
      fetchSentRequests();
      setSearchQuery('');
      setSearchResults([]);
    } catch (error) {
      console.error('Error sending mate request:', error);
      toast.error(error.response?.data?.message || 'Failed to send mate request');
    }
  };

  // Debounce search
  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(searchQuery);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  // Initial data fetch
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        fetchMates(),
        fetchMateRequests(),
        fetchSentRequests()
      ]);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-4"></div>
        <p className="text-gray-600">Loading...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          {/* Search Box */}
          <div className="sticky top-0 z-10 p-4 border-b border-gray-200 bg-white/95 backdrop-blur-sm">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaSearch className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search users to add as mates..."
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-full focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              {isSearching && (
                <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
                </div>
              )}
            </div>
            {searchResults.length > 0 && (
              <div className="absolute z-20 mt-2 w-full bg-white rounded-lg shadow-lg border border-gray-200 max-h-60 overflow-y-auto">
                {searchResults.map((user) => (
                  <div
                    key={user._id}
                    className="p-3 hover:bg-gray-50 cursor-pointer flex items-center justify-between"
                  >
                    <div className="flex items-center space-x-3">
                      <div className="w-10 h-10 rounded-full overflow-hidden bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center">
                        {user.profileImage ? (
                          <img
                            src={getUploadUrl(user.profileImage)}
                            alt={user.username}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <span className="text-white text-lg">{user.username[0].toUpperCase()}</span>
                        )}
                      </div>
                      <div>
                        <p className="font-medium text-gray-900">{user.username}</p>
                        <p className="text-sm text-gray-500">{user.email}</p>
                      </div>
                    </div>
                    <button
                      onClick={() => handleSendRequest(user._id)}
                      className="ml-4 px-3 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors text-sm flex items-center space-x-1"
                    >
                      <FaUserPlus className="h-4 w-4" />
                      <span>Add Mate</span>
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Tabs */}
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              <button
                onClick={() => setActiveTab('mates')}
                className={`flex-1 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                  activeTab === 'mates'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                My Mates ({mates.length})
              </button>
              <button
                onClick={() => setActiveTab('requests')}
                className={`flex-1 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                  activeTab === 'requests'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                Requests ({mateRequests.length})
              </button>
              <button
                onClick={() => setActiveTab('sent')}
                className={`flex-1 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                  activeTab === 'sent'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                Sent ({sentRequests.length})
              </button>
            </nav>
          </div>

          {/* Content */}
          <div className="divide-y divide-gray-200">
            {activeTab === 'mates' && (
              <>
                {mates.length > 0 ? (
                  mates.map((mate) => (
                    <div key={mate._id} className="p-4 hover:bg-gray-50 transition-colors">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="w-12 h-12 rounded-full overflow-hidden bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center">
                            {mate.profileImage ? (
                              <img
                                src={getUploadUrl(mate.profileImage)}
                                alt={mate.username}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <span className="text-white text-lg">
                                {mate.username[0].toUpperCase()}
                              </span>
                            )}
                          </div>
                          <div>
                            <p className="text-sm font-medium text-gray-900">{mate.username}</p>
                            <p className="text-sm text-gray-500">{mate.email}</p>
                          </div>
                        </div>
                        <button
                          onClick={() => navigate(`/messages/${mate._id}`)}
                          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors text-sm"
                        >
                          Message
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-8 text-center">
                    <div className="text-gray-400 mb-4">
                      <FaUserPlus className="h-12 w-12 mx-auto" />
                    </div>
                    <p className="text-gray-500 mb-2">No mates yet</p>
                    <p className="text-sm text-gray-400">
                      Search for users to add them as mates
                    </p>
                  </div>
                )}
              </>
            )}

            {activeTab === 'requests' && (
              <>
                {mateRequests.length > 0 ? (
                  mateRequests.map((request) => (
                    <div key={request._id} className="p-4 hover:bg-gray-50 transition-colors">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="w-12 h-12 rounded-full overflow-hidden bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center">
                            {request.profileImage ? (
                              <img
                                src={getUploadUrl(request.profileImage)}
                                alt={request.username}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <span className="text-white text-lg">
                                {request.username[0].toUpperCase()}
                              </span>
                            )}
                          </div>
                          <div>
                            <p className="text-sm font-medium text-gray-900">{request.username}</p>
                            <p className="text-sm text-gray-500">Wants to be your mate</p>
                          </div>
                        </div>
                        <div className="flex space-x-2">
                          <button
                            onClick={() => handleAcceptRequest(request._id)}
                            className="inline-flex items-center px-3 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors text-sm"
                          >
                            <FaCheck className="h-4 w-4 mr-1" />
                            Accept
                          </button>
                          <button
                            onClick={() => handleRejectRequest(request._id)}
                            className="inline-flex items-center px-3 py-1.5 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors text-sm"
                          >
                            <FaTimes className="h-4 w-4 mr-1" />
                            Decline
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-8 text-center">
                    <div className="text-gray-400 mb-4">
                      <FaUserPlus className="h-12 w-12 mx-auto" />
                    </div>
                    <p className="text-gray-500 mb-2">No pending requests</p>
                    <p className="text-sm text-gray-400">
                      When someone sends you a mate request, it will appear here
                    </p>
                  </div>
                )}
              </>
            )}

            {activeTab === 'sent' && (
              <>
                {sentRequests.length > 0 ? (
                  sentRequests.map((request) => (
                    <div key={request._id} className="p-4 hover:bg-gray-50 transition-colors">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="w-12 h-12 rounded-full overflow-hidden bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center">
                            {request.profileImage ? (
                              <img
                                src={getUploadUrl(request.profileImage)}
                                alt={request.username}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <span className="text-white text-lg">
                                {request.username[0].toUpperCase()}
                              </span>
                            )}
                          </div>
                          <div>
                            <p className="text-sm font-medium text-gray-900">{request.username}</p>
                            <p className="text-sm text-gray-500">Request pending</p>
                          </div>
                        </div>
                        <button
                          onClick={() => handleCancelRequest(request._id)}
                          className="px-3 py-1.5 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg transition-colors"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-8 text-center">
                    <div className="text-gray-400 mb-4">
                      <FaUserPlus className="h-12 w-12 mx-auto" />
                    </div>
                    <p className="text-gray-500 mb-2">No sent requests</p>
                    <p className="text-sm text-gray-400">
                      Search for users to send them mate requests
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mates; 