import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useMessages } from '../context/MessageContext';
import { useNotifications } from '../context/NotificationsContext';
import NotificationBell from './NotificationBell';
import MessageCenter from './MessageCenter';
import { getUploadUrl } from '../utils/helpers';
import { motion, AnimatePresence } from 'framer-motion';
import { PlusIcon } from '@heroicons/react/24/solid';

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const isAdmin = user?.role === 'admin' || user?.role === 'superadmin';

  return (
    <nav className="bg-gray-50 shadow-sm fixed w-full top-0 z-20 border-b border-purple-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center space-x-3">
            <Link to="/" className="flex items-center space-x-2">
              <img
                src="/favicon/web-app-manifest-192x192.png"
                alt="RantUK Logo"
                className="h-8 w-8"
              />
              <span className="text-xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
                RantUK
              </span>
            </Link>
          </div>

          <div className="flex items-center space-x-4">
            {user ? (
              <>
                <Link
                  to="/create"
                  className="md:hidden flex items-center justify-center w-10 h-10 rounded-full bg-gradient-to-r from-blue-600 to-purple-600 text-white shadow-lg hover:opacity-90 transition-opacity"
                >
                  <PlusIcon className="w-6 h-6" />
                </Link>

                <MessageCenter />
                <NotificationBell />
                <div className="relative">
                  <button
                    onClick={() => setShowDropdown(!showDropdown)}
                    className="flex items-center space-x-2 text-gray-700 hover:text-purple-600 transition-colors focus:outline-none"
                  >
                    <div className="h-8 w-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 flex items-center justify-center overflow-hidden">
                      {user?.profileImage ? (
                        <img
                          src={getUploadUrl(user.profileImage)}
                          alt={user.username}
                          className="h-full w-full object-cover"
                        />
                      ) : (
                        <span className="text-white text-sm font-medium">
                          {user?.username?.charAt(0)?.toUpperCase()}
                        </span>
                      )}
                    </div>
                  </button>

                  <AnimatePresence>
                    {showDropdown && (
                      <>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-30"
                          onClick={() => setShowDropdown(false)}
                        />
                        <motion.div
                          initial={{ opacity: 0, scale: 0.95, y: -20 }}
                          animate={{ opacity: 1, scale: 1, y: 0 }}
                          exit={{ opacity: 0, scale: 0.95, y: -20 }}
                          className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg z-40 border border-gray-100 overflow-hidden"
                        >
                          <div className="py-2">
                            <div className="px-4 py-3 border-b border-gray-100">
                              <p className="text-sm font-medium text-gray-900">{user.username}</p>
                              <p className="text-xs text-gray-500 mt-1">{user.email}</p>
                            </div>
                            <Link
                              to={`/profile/${user?.username}`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors"
                              onClick={() => setShowDropdown(false)}
                            >
                              Profile
                            </Link>
                            {isAdmin && (
                              <Link
                                to="/admin"
                                className="block px-4 py-2 text-sm text-purple-600 hover:bg-purple-50 transition-colors"
                                onClick={() => setShowDropdown(false)}
                              >
                                Admin Dashboard
                              </Link>
                            )}
                            <Link
                              to="/settings"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-blue-600 transition-colors"
                              onClick={() => setShowDropdown(false)}
                            >
                              Settings
                            </Link>
                            <button
                              onClick={handleLogout}
                              className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors"
                            >
                              Sign out
                            </button>
                          </div>
                        </motion.div>
                      </>
                    )}
                  </AnimatePresence>
                </div>
              </>
            ) : (
              <div className="flex items-center space-x-4">
                <Link
                  to="/login"
                  className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-600 font-medium hover:opacity-80 transition-opacity"
                >
                  Sign in
                </Link>
                <Link
                  to="/register"
                  className="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:opacity-90 transition-opacity"
                >
                  Sign up
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 