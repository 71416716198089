import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { toast } from 'react-hot-toast';
import { useAuth } from '../context/AuthContext';
import { useWebSocket } from '../context/WebSocketContext';
import api from '../utils/axiosConfig';
import { getUploadUrl } from '../utils/helpers';
import VoteButtons from './VoteButtons';
import LinkPreview from './LinkPreview';
import ImageViewer from './ImageViewer';
import UserModal from './UserModal';
import { ShareIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

// Helper function to extract URLs from text
function extractUrls(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.match(urlRegex) || [];
}

const RantCard = ({ rant, onVoteUpdate }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { socket } = useWebSocket();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (socket && rant) {
      const handleMessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === 'rantLiked' && data.rantId === rant._id) {
            onVoteUpdate?.({ ...rant, likes: data.likes });
          }
        } catch (error) {
          console.error('Error handling WebSocket message:', error);
        }
      };

      socket.addEventListener('message', handleMessage);

      return () => {
        socket.removeEventListener('message', handleMessage);
      };
    }
  }, [socket, rant, onVoteUpdate]);

  useEffect(() => {
    if (!rant.video || !videoRef.current) return;

    const videoElement = videoRef.current;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // 50% of the video must be visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!videoElement) return;
        
        if (entry.isIntersecting) {
          // Video is in view
          videoElement.play().catch((error) => {
            console.log("Autoplay prevented:", error);
          });
        } else {
          // Video is out of view
          videoElement.pause();
        }
      });
    }, options);

    observer.observe(videoElement);

    return () => {
      observer.unobserve(videoElement);
      observer.disconnect();
    };
  }, [rant.video]);

  const handleShare = async (e) => {
    e.stopPropagation(); // Prevent card click
    const shareUrl = `${window.location.origin}/rant/${rant._id}`;
    try {
      if (navigator.share) {
        await navigator.share({
          title: `Rant by ${rant.user.username}`,
          text: rant.content.substring(0, 100) + (rant.content.length > 100 ? '...' : ''),
          url: shareUrl
        });
      } else {
        await navigator.clipboard.writeText(shareUrl);
        toast.success('Link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      toast.error('Failed to share');
    }
  };

  const handleUserClick = (e) => {
    e.stopPropagation(); // Prevent card click
    setShowUserModal(true);
  };

  const handleImageClick = (e, imageUrl) => {
    e.stopPropagation(); // Prevent card click
    setSelectedImage(imageUrl);
  };

  const handleCardClick = () => {
    navigate(`/rant/${rant._id}`);
  };

  const formatContent = (content) => {
    if (!content) return '';
    // Remove URLs from content when they will be shown as previews
    const urls = extractUrls(content);
    if (urls && urls.length > 0) {
      return content.replace(urls[0], '').trim();
    }
    return content;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      onClick={handleCardClick}
      className="bg-white rounded-2xl shadow-sm hover:shadow-xl transition-all duration-300 border border-gray-100 cursor-pointer"
    >
      <div className="p-6">
        {/* User info and share button */}
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <div 
              className="cursor-pointer transform hover:scale-105 transition-transform duration-200"
              onClick={handleUserClick}
            >
              {rant.user?.profileImage ? (
                <img
                  src={getUploadUrl(rant.user.profileImage)}
                  alt={rant.user.username}
                  className="w-12 h-12 rounded-full object-cover ring-2 ring-offset-2 ring-gray-100"
                />
              ) : (
                <div className="w-12 h-12 rounded-full bg-gradient-to-br from-blue-500 to-purple-500 flex items-center justify-center ring-2 ring-offset-2 ring-gray-100">
                  <span className="text-white font-medium">
                    {rant.user?.username?.[0]?.toUpperCase()}
                  </span>
                </div>
              )}
            </div>
            <div className="ml-4">
              <button
                onClick={handleUserClick}
                className="text-lg font-semibold text-gray-900 hover:text-blue-600 transition-colors duration-200"
              >
                {rant.user?.username}
              </button>
              <p className="text-sm text-gray-500">
                {formatDistanceToNow(new Date(rant.createdAt), { addSuffix: true })}
              </p>
            </div>
          </div>
          <button
            onClick={handleShare}
            className="p-2 text-gray-500 hover:text-blue-500 transition-colors duration-200 rounded-full hover:bg-gray-50"
            title="Share rant"
          >
            <ShareIcon className="w-5 h-5" />
          </button>
        </div>

        {/* Rant content */}
        <div className="prose max-w-none">
          <p className="text-gray-800 whitespace-pre-wrap text-[15px] leading-relaxed">
            {formatContent(rant.content)}
          </p>
        </div>

        {/* Link Preview */}
        {extractUrls(rant.content)[0] && (
          <div className="mt-4">
            <LinkPreview url={extractUrls(rant.content)[0]} />
          </div>
        )}

        {/* Media Content */}
        {(rant.images?.length > 0 || rant.video) && (
          <div className="mt-4 grid gap-2 grid-cols-1">
            {/* Video */}
            {rant.video && (
              <div
                className="relative aspect-video rounded-lg overflow-hidden cursor-pointer group bg-gray-50"
              >
                <video
                  ref={videoRef}
                  src={getUploadUrl(rant.video.url)}
                  poster={rant.video.thumbnail ? getUploadUrl(rant.video.thumbnail) : undefined}
                  controls
                  muted
                  playsInline
                  loop
                  className="w-full h-full object-cover"
                  preload="metadata"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            )}

            {/* Images */}
            {rant.images?.length > 0 && (
              <div className={`grid gap-2 ${
                rant.images.length === 1 ? 'grid-cols-1' : 'grid-cols-2'
              }`}>
                {rant.images.map((image, index) => (
                  <div
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedImage(getUploadUrl(image.url));
                    }}
                    className="relative aspect-square rounded-lg overflow-hidden cursor-pointer group bg-gray-50"
                  >
                    <img
                      src={getUploadUrl(image.url)}
                      alt={image.caption || `Image ${index + 1}`}
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                    <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity duration-200" />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Vote buttons and category */}
        <div className="mt-6 flex items-center justify-between" onClick={e => e.stopPropagation()}>
          <VoteButtons
            rantId={rant._id}
            likes={rant.likes}
            commentCount={rant.comments?.length || 0}
            showCommentBubble={true}
            onVoteUpdate={onVoteUpdate}
          />
          {rant.category && (
            <span className="px-4 py-1.5 bg-gray-50 text-gray-600 rounded-full text-sm font-medium border border-gray-100">
              {rant.category}
            </span>
          )}
        </div>
      </div>

      {/* Modals */}
      {selectedImage && (
        <ImageViewer
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}

      {showUserModal && (
        <UserModal
          userId={rant.user._id}
          onClose={() => setShowUserModal(false)}
        />
      )}
    </motion.div>
  );
};

export default RantCard; 