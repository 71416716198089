import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../context/NotificationsContext';
import { getUploadUrl } from '../utils/helpers';
import { formatDistanceToNow } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-hot-toast';
import api from '../utils/axiosConfig';
import { FaUserPlus, FaEnvelope, FaBell, FaThumbsUp, FaComment } from 'react-icons/fa';

const NotificationBell = () => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const { notifications, markAsRead, markAllAsRead } = useNotifications();
  const unreadCount = notifications?.filter(n => !n.read)?.length || 0;
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleNotificationClick = async (notification) => {
    try {
      await markAsRead(notification._id);
      setShowDropdown(false);
      
      switch (notification.type) {
        case 'mate_request':
        case 'mate_request_accepted':
          navigate('/messages', { state: { activeTab: 'mates' } });
          break;
        case 'new_message':
          navigate(`/messages/${notification.sender._id}`);
          break;
        case 'like':
          navigate(`/rant/${notification.rant._id}`);
          break;
        case 'like_comment':
          navigate(`/rant/${notification.rant._id}`, { 
            state: { highlightCommentId: notification.comment._id }
          });
          break;
        case 'comment':
        case 'mention':
          navigate(`/rant/${notification.rant._id}`, {
            state: { highlightCommentId: notification.comment?._id }
          });
          break;
        default:
          console.warn('Unknown notification type:', notification.type);
          if (notification.rant?._id) {
            navigate(`/rant/${notification.rant._id}`);
          }
      }
    } catch (error) {
      console.error('Error handling notification click:', error);
      toast.error('Failed to process notification');
    }
  };

  const handleMarkAllClick = async () => {
    try {
      await markAllAsRead();
      setShowDropdown(false);
    } catch (error) {
      console.error('Error marking all as read:', error);
      toast.error('Failed to mark notifications as read');
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'mate_request':
      case 'mate_request_accepted':
        return <FaUserPlus className="w-4 h-4" />;
      case 'new_message':
        return <FaEnvelope className="w-4 h-4" />;
      case 'like':
      case 'like_comment':
        return <FaThumbsUp className="w-4 h-4" />;
      case 'comment':
      case 'mention':
        return <FaComment className="w-4 h-4" />;
      default:
        return <FaBell className="w-4 h-4" />;
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        className="relative p-2 text-gray-600 hover:text-blue-500 transition-all duration-200 rounded-full hover:bg-blue-50 active:scale-95"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
        {unreadCount > 0 && (
          <motion.span
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="absolute top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white transform translate-x-1/2 -translate-y-1/2 bg-gradient-to-r from-red-500 to-pink-500 rounded-full shadow-lg"
          >
            {unreadCount}
          </motion.span>
        )}
      </button>

      <AnimatePresence>
        {showDropdown && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="fixed sm:absolute right-4 sm:right-0 mt-2 w-[calc(100vw-2rem)] sm:w-96 bg-white rounded-xl shadow-2xl z-50 max-h-[80vh] overflow-y-auto border border-gray-100/50 backdrop-blur-xl"
          >
            <div className="p-4 border-b border-gray-100/50 flex justify-between items-center bg-gradient-to-r from-blue-50 to-purple-50">
              <h3 className="text-lg font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">Notifications</h3>
              {unreadCount > 0 && (
                <button
                  onClick={handleMarkAllClick}
                  className="px-3 py-1 text-sm font-medium text-blue-600 hover:text-blue-700 bg-blue-50 hover:bg-blue-100 rounded-full transition-all duration-200"
                >
                  Mark all as read
                </button>
              )}
            </div>

            {!notifications || notifications.length === 0 ? (
              <div className="p-8 text-center">
                <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-gradient-to-r from-blue-50 to-purple-50 flex items-center justify-center">
                  <span className="text-3xl">🔔</span>
                </div>
                <p className="text-gray-500">No notifications yet</p>
                <p className="text-gray-400 text-sm mt-2">
                  We'll notify you when something happens
                </p>
              </div>
            ) : (
              <div className="divide-y divide-gray-100">
                {notifications.map((notification) => (
                  <motion.div
                    key={notification._id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    onClick={() => handleNotificationClick(notification)}
                    className={`p-4 hover:bg-gradient-to-r hover:from-blue-50/50 hover:to-purple-50/50 cursor-pointer transition-all duration-200 ${
                      !notification.read ? 'bg-gradient-to-r from-blue-50 to-purple-50' : ''
                    }`}
                  >
                    <div className="flex items-center gap-3">
                      <div className="flex-shrink-0 w-10 sm:w-12 relative">
                        {!notification.read && (
                          <div className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full ring-2 ring-white z-10" />
                        )}
                        {notification.sender?.profileImage ? (
                          <img
                            src={getUploadUrl(notification.sender.profileImage)}
                            alt={notification.sender.username}
                            className="w-10 h-10 sm:w-12 sm:h-12 rounded-full object-cover ring-2 ring-white shadow-lg"
                          />
                        ) : (
                          <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-gradient-to-br from-blue-400 to-purple-500 flex items-center justify-center text-white text-base sm:text-lg font-semibold shadow-lg ring-2 ring-white">
                            {notification.sender?.username?.[0]?.toUpperCase()}
                          </div>
                        )}
                      </div>
                      <div className="flex-1 min-w-0 overflow-hidden">
                        <div className="flex items-center justify-between space-x-2">
                          <p className="text-sm font-medium text-gray-900 truncate flex-1">
                            {getNotificationIcon(notification.type)}{' '}
                            <span className="ml-1">{notification.message}</span>
                          </p>
                          <span className="text-xs text-gray-500 flex-shrink-0">
                            {formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })}
                          </span>
                        </div>
                        {notification.rant?.content && (
                          <p className="mt-1 text-sm text-gray-500 truncate">
                            {notification.rant.content}
                          </p>
                        )}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NotificationBell; 