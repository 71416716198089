import React from 'react';
import { Link } from 'react-router-dom';

export default function Terms() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
          
          <div className="space-y-6 text-gray-600">
            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">1. Age Requirement</h2>
              <p>You must be at least 16 years old to use RantUK.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">2. User Conduct</h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>You agree to post content that does not violate any laws or rights</li>
                <li>You are responsible for all content you post</li>
                <li>Hate speech, harassment, or threatening behavior is not allowed</li>
                <li>Respect other users and their opinions</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">3. Content Guidelines</h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>Keep discussions respectful and constructive</li>
                <li>No spam, scams, or misleading content</li>
                <li>No explicit or adult content</li>
                <li>No personal information of others</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">4. Account Security</h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>You are responsible for maintaining your account security</li>
                <li>Do not share your login credentials</li>
                <li>Report any unauthorized access immediately</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">5. Termination</h2>
              <p>We reserve the right to suspend or terminate accounts that violate these terms.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">6. Changes to Terms</h2>
              <p>We may update these terms. Continued use after changes means you accept the new terms.</p>
            </section>
          </div>

          <div className="mt-8 pt-8 border-t border-gray-200">
            <Link to="/register" className="text-blue-600 hover:text-blue-800 font-medium">
              ← Back to Registration
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 