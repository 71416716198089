import React, { useState, useEffect, useRef } from 'react';
import api from '../utils/axiosConfig';

const LinkPreview = ({ url }) => {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageError, setImageError] = useState(false);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    const fetchMetadata = async () => {
      // Cancel any previous request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create new abort controller for this request
      abortControllerRef.current = new AbortController();

      try {
        setLoading(true);
        setError(null);
        setImageError(false);

        const response = await api.get(`/rants/url-metadata?url=${encodeURIComponent(url)}`, {
          signal: abortControllerRef.current.signal,
          // Specific timeout for this request
          timeout: 30000 // 30 seconds
        });

        if (response.data) {
          setPreview(response.data);
        } else {
          setError('No preview available');
        }
      } catch (err) {
        // Don't set error for aborted requests
        if (err.name === 'AbortError' || err.code === 'ECONNABORTED') {
          console.log('Request cancelled or timed out:', url);
        } else {
          console.error('Error fetching URL metadata:', err);
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };

    if (url) {
      fetchMetadata();
    }

    // Cleanup function to cancel request when component unmounts or URL changes
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [url]);

  // Don't show anything if there's no URL or if there's an error
  if (!url || error) return null;
  
  // Show loading state
  if (loading) {
    return (
      <div className="animate-pulse bg-gray-100 rounded-lg overflow-hidden">
        <div className="flex">
          <div className="w-1/3 h-[120px] bg-gray-200"></div>
          <div className="flex-1 p-4">
            <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
            <div className="h-6 bg-gray-200 rounded w-3/4 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          </div>
        </div>
      </div>
    );
  }

  // Don't show anything if no preview data
  if (!preview) return null;

  return (
    <a
      href={preview.url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()}
      className="block border border-gray-200 rounded-lg overflow-hidden hover:shadow-md transition-shadow duration-200"
    >
      <div className="flex">
        {preview.image && !imageError && (
          <div className="w-1/3 min-h-[120px] bg-gray-100">
            <img
              src={preview.image}
              alt={preview.title || 'Link preview'}
              className="w-full h-full object-cover"
              onError={() => setImageError(true)}
              loading="lazy"
            />
          </div>
        )}
        <div className={`flex-1 p-4 ${!preview.image || imageError ? 'w-full' : ''}`}>
          <div className="text-sm text-gray-500 mb-1">{preview.siteName}</div>
          <h3 className="font-medium text-gray-900 mb-1 line-clamp-2">{preview.title}</h3>
          {preview.description && (
            <p className="text-sm text-gray-600 line-clamp-2">{preview.description}</p>
          )}
        </div>
      </div>
    </a>
  );
};

export default LinkPreview; 