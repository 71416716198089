// Default profile image as a data URL (a simple avatar)
const DEFAULT_PROFILE_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLXVzZXIiPjxwYXRoIGQ9Ik0yMCAyMXYtMmE0IDQgMCAwIDAtNC00SDhhNCA0IDAgMCAwLTQgNHYyIj48L3BhdGg+PGNpcmNsZSBjeD0iMTIiIGN5PSI3IiByPSI0Ij48L2NpcmNsZT48L3N2Zz4=';

// Helper function to get full URL for uploaded files
export const getUploadUrl = (path) => {
  if (!path) return DEFAULT_PROFILE_IMAGE;
  
  // If it's already a full URL, return it
  if (path.startsWith('http://') || path.startsWith('https://')) {
    return path;
  }

  // If it's a data URL, return it
  if (path.startsWith('data:')) {
    return path;
  }

  // Remove any leading slashes and ensure correct case
  const cleanPath = path.replace(/^\/+/, '').replace(/^Uploads\//i, 'uploads/');
  
  // Construct the full URL
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://rantuk.com' : 'http://localhost:5001';
  
  // If the path already includes 'uploads', just append it to the base URL
  if (cleanPath.includes('uploads/')) {
    return `${baseUrl}/${cleanPath}`;
  }
  
  // Handle different types of media
  if (cleanPath.includes('profile')) {
    return `${baseUrl}/uploads/profiles/${cleanPath.replace(/^profiles\//, '')}`;
  } else if (cleanPath.includes('videos/')) {
    return `${baseUrl}/uploads/rants/videos/${cleanPath.replace(/^videos\//, '')}`;
  } else if (cleanPath.includes('rants/')) {
    return `${baseUrl}/uploads/rants/${cleanPath.replace(/^rants\//, '')}`;
  }
  
  // Default case: just append to uploads
  return `${baseUrl}/uploads/${cleanPath}`;
};

// Specific helper for profile images
export const getProfileImageUrl = (path) => {
  if (!path) return DEFAULT_PROFILE_IMAGE;
  return getUploadUrl(path);
}; 