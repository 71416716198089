import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaTimes } from 'react-icons/fa';
import api from '../utils/api';
import { toast } from 'react-hot-toast';
import { getUploadUrl } from '../utils/helpers';
import { useAuth } from '../context/AuthContext';
import ConfirmModal from './ConfirmModal';

const UserSearch = ({ isOpen, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Reset search when modal opens
  useEffect(() => {
    if (isOpen) {
      setSearchQuery('');
      setSearchResults([]);
    }
  }, [isOpen]);

  // Helper function to timeout a promise
  const timeoutPromise = (promise, ms = 5000) => {
    return Promise.race([
      promise,
      new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Request timed out')), ms)
      )
    ]);
  };

  // Search function
  const searchUsers = useCallback(async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      setIsLoading(false);
      return;
    }

    console.log('Starting search with query:', query);
    setIsLoading(true);
    setSearchResults([]); // Clear results while loading

    try {
      const timestamp = Date.now();
      const response = await timeoutPromise(
        api.get(`/users/search?q=${encodeURIComponent(query.trim())}&_t=${timestamp}`)
      );
      
      console.log('Raw search response:', response.data);
      
      if (!response.data) {
        console.log('No response data');
        setSearchResults([]);
        setIsLoading(false);
        return;
      }

      // Filter out current user
      const filteredResults = response.data.filter(user => 
        currentUser ? user._id !== currentUser._id : true
      );
      console.log('Filtered results:', filteredResults);

      // Show initial results immediately with default status
      const initialResults = filteredResults.map(user => ({
        ...user,
        isMate: false,
        hasSentRequest: false,
        isFollowing: false
      }));
      setSearchResults(initialResults);
      setIsLoading(false);

      // Update status in background
      for (const user of filteredResults) {
        try {
          // Get mate status and request status
          const [mateRes, requestRes] = await Promise.all([
            timeoutPromise(
              api.get(`/mates/status/${user._id}?_t=${timestamp}`),
              3000
            ),
            timeoutPromise(
              api.get(`/mates/request/${user._id}?_t=${timestamp}`),
              3000
            )
          ]);
          console.log(`Mate status for ${user.username}:`, mateRes.data);
          console.log(`Request status for ${user.username}:`, requestRes.data);

          // Update user with mate status immediately
          setSearchResults(prev => 
            prev.map(u => 
              u._id === user._id 
                ? {
                    ...u,
                    isMate: mateRes.data.isMate,
                    hasSentRequest: requestRes.data.hasSentRequest,
                    hasReceivedRequest: requestRes.data.hasReceivedRequest,
                    isFollowing: mateRes.data.isMate ? true : mateRes.data.isFollowing
                  }
                : u
            )
          );
        } catch (error) {
          console.error(`Error fetching status for ${user.username}:`, error);
        }
      }
    } catch (error) {
      console.error('Search error:', error);
      toast.error('Failed to search users');
      setSearchResults([]);
      setIsLoading(false);
    }
  }, [currentUser]);

  // Reduce debounce time for faster response
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery) {
        console.log('Initiating search for:', searchQuery);
        searchUsers(searchQuery);
      } else {
        setSearchResults([]);
        setIsLoading(false);
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [searchQuery, searchUsers]);

  // Add debug logging for render
  useEffect(() => {
    console.log('Render state:', { isLoading, searchResults, searchQuery });
  }, [isLoading, searchResults, searchQuery]);

  const handleAddMate = async (userId, username, currentStatus) => {
    if (!currentUser) {
      toast.error('Please log in to add mates');
      return;
    }

    if (currentStatus.isMate) {
      setSelectedUser({ _id: userId, username });
      setShowConfirmModal(true);
      return;
    }

    try {
      if (currentStatus.hasSentRequest) {
        // Cancel mate request
        await api.post(`/mates/cancel/${userId}`);
        toast.success(`Cancelled mate request to ${username}`);
        
        setSearchResults(prev => 
          prev.map(user => 
            user._id === userId 
              ? { ...user, hasSentRequest: false }
              : user
          )
        );
      } else if (currentStatus.hasReceivedRequest) {
        // Accept mate request
        await api.post(`/mates/accept/${userId}`);
        toast.success(`Accepted mate request from ${username}`);
        
        setSearchResults(prev => 
          prev.map(user => 
            user._id === userId 
              ? { ...user, hasReceivedRequest: false, isMate: true, isFollowing: true }
              : user
          )
        );
      } else {
        try {
          // Send mate request first
          await api.post(`/mates/request/${userId}`);
          
          // Check follow status before trying to follow
          const followStatus = await api.get(`/users/${userId}/follow-status`);
          if (!followStatus.data.isFollowing) {
            try {
              await api.post(`/users/${userId}/follow`);
            } catch (followError) {
              console.error('Follow error:', followError);
              // Don't fail if follow fails
            }
          }
          
          toast.success(`Mate request sent to ${username}`);
          
          setSearchResults(prev => 
            prev.map(user => 
              user._id === userId 
                ? { ...user, hasSentRequest: true, isFollowing: true }
                : user
            )
          );
        } catch (error) {
          if (error.response?.data?.message === 'Mate request already sent') {
            // Update UI to show request is pending
            setSearchResults(prev => 
              prev.map(user => 
                user._id === userId 
                  ? { ...user, hasSentRequest: true }
                  : user
              )
            );
            toast.success(`Mate request already sent to ${username}`);
          } else {
            throw error;
          }
        }
      }
    } catch (error) {
      console.error('Mate action error:', error);
      toast.error(error.response?.data?.message || 'Failed to perform mate action');
    }
  };

  const handleFollow = async (userId, username, currentStatus) => {
    try {
      if (currentStatus.isFollowing) {
        // Unfollow
        await api.delete(`/users/${userId}/follow`);
        toast.success(`Unfollowed ${username}`);
        
        setSearchResults(prev => 
          prev.map(user => 
            user._id === userId ? { ...user, isFollowing: false } : user
          )
        );
      } else {
        // Follow
        await api.post(`/users/${userId}/follow`);
        toast.success(`Now following ${username}`);
        
        setSearchResults(prev => 
          prev.map(user => 
            user._id === userId ? { ...user, isFollowing: true } : user
          )
        );
      }
    } catch (error) {
      console.error('Follow action error:', error);
      toast.error('Failed to perform follow action');
    }
  };

  const handleProfileClick = (username) => {
    navigate(`/profile/${username}`);
    onClose();
  };

  const handleConfirmRemoveMate = async () => {
    if (!selectedUser) return;

    try {
      await api.delete(`/mates/${selectedUser._id}`);
      setSearchResults(prev => 
        prev.map(user => 
          user._id === selectedUser._id 
            ? { ...user, isMate: false, isFollowing: false }
            : user
        )
      );
      toast.success(`Removed ${selectedUser.username} from mates`);
    } catch (error) {
      console.error('Error removing mate:', error);
      toast.error(error.response?.data?.message || 'Failed to remove mate');
    } finally {
      setSelectedUser(null);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[9999] flex items-start justify-center pt-20">
      <div className="bg-white rounded-xl w-full max-w-lg mx-4 shadow-2xl">
        <div className="p-4 border-b border-gray-200 flex items-center gap-3">
          <div className="flex-1 relative">
            <input
              type="text"
              id="user-search"
              name="user-search"
              placeholder="Search users..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-gray-50 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              autoFocus
            />
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <FaTimes className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        <div className="max-h-[60vh] overflow-y-auto">
          {isLoading && searchResults.length === 0 ? (
            <div className="p-4 text-center text-gray-500">
              Searching...
            </div>
          ) : searchResults.length > 0 ? (
            <div className="p-2">
              {searchResults.map(user => {
                console.log('Rendering user:', user);
                return (
                  <div
                    key={user._id}
                    className="p-3 hover:bg-gray-50 rounded-lg flex items-center justify-between gap-4"
                  >
                    <div 
                      className="flex items-center gap-3 flex-1 cursor-pointer"
                      onClick={() => handleProfileClick(user.username)}
                    >
                      <div className="w-12 h-12 rounded-full overflow-hidden bg-gradient-to-r from-purple-500 to-blue-500 flex-shrink-0">
                        {user.profileImage ? (
                          <img
                            src={getUploadUrl(user.profileImage)}
                            alt={user.username}
                            className="w-full h-full object-cover"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `https://ui-avatars.com/api/?name=${user.username}&background=random`;
                            }}
                          />
                        ) : (
                          <div className="w-full h-full flex items-center justify-center text-white text-lg font-medium">
                            {user.username[0].toUpperCase()}
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="font-medium text-gray-900 hover:text-blue-600 transition-colors">
                          @{user.username}
                        </div>
                        {user.isMate && (
                          <div className="text-sm text-purple-600">Mate</div>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      {user.isMate ? (
                        <button
                          onClick={() => handleAddMate(user._id, user.username, user)}
                          className="px-3 py-1.5 bg-gray-100 text-red-500 rounded-lg text-sm font-medium hover:bg-gray-200"
                        >
                          Remove Mate
                        </button>
                      ) : user.hasSentRequest ? (
                        <button
                          onClick={() => handleAddMate(user._id, user.username, user)}
                          className="px-3 py-1.5 bg-gray-100 text-gray-500 rounded-lg text-sm font-medium hover:bg-gray-200"
                        >
                          Cancel Request
                        </button>
                      ) : user.hasReceivedRequest ? (
                        <button
                          onClick={() => handleAddMate(user._id, user.username, user)}
                          className="px-3 py-1.5 bg-gradient-to-r from-green-500 to-blue-500 text-white rounded-lg text-sm font-medium hover:shadow-md transition-all"
                        >
                          Accept Request
                        </button>
                      ) : (
                        <button
                          onClick={() => handleAddMate(user._id, user.username, user)}
                          className="px-3 py-1.5 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-lg text-sm font-medium hover:shadow-md transition-all"
                        >
                          Add Mate
                        </button>
                      )}
                      
                      {/* Only show follow button if not mates */}
                      {!user.isMate && (
                        <button
                          onClick={() => handleFollow(user._id, user.username, user)}
                          className={`px-3 py-1.5 ${
                            user.isFollowing 
                              ? 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          } rounded-lg text-sm font-medium transition-all`}
                        >
                          {user.isFollowing ? 'Unfollow' : 'Follow'}
                        </button>
                      )}
                      
                      {/* Only show message button if they are mates */}
                      {user.isMate && (
                        <button
                          onClick={() => {
                            window.location.href = `/messages/${user._id}`;
                            onClose();
                          }}
                          className="px-3 py-1.5 bg-gray-100 text-gray-700 rounded-lg text-sm font-medium hover:bg-gray-200 transition-all"
                        >
                          Message
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : searchQuery ? (
            <div className="p-4 text-center text-gray-500">
              No users found
            </div>
          ) : null}
        </div>
      </div>
      
      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
          setSelectedUser(null);
        }}
        onConfirm={handleConfirmRemoveMate}
        title="Remove Mate"
        message={`Are you sure you want to remove ${selectedUser?.username} from your mates? You will also stop following each other.`}
        confirmText="Remove"
        type="danger"
      />
    </div>
  );
};

export default UserSearch; 