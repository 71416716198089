import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import api from '../utils/axiosConfig';
import { toast } from 'react-hot-toast';

const AuthContext = createContext(null);

const TOKEN_KEY = 'auth_token';
const USER_KEY = 'auth_user';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    try {
      const savedUser = localStorage.getItem(USER_KEY);
      return savedUser ? JSON.parse(savedUser) : null;
    } catch {
      return null;
    }
  });
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return !!localStorage.getItem(TOKEN_KEY);
  });
  
  const authCheckTimeoutRef = useRef(null);
  const lastAuthCheckRef = useRef(Date.now());

  const persistAuth = useCallback((token, userData) => {
    if (token) {
      localStorage.setItem(TOKEN_KEY, token);
    }
    if (userData) {
      localStorage.setItem(USER_KEY, JSON.stringify(userData));
      setUser(userData);
    }
    setIsAuthenticated(true);
    lastAuthCheckRef.current = Date.now();
  }, []);

  const clearAuth = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    setUser(null);
    setIsAuthenticated(false);
    if (authCheckTimeoutRef.current) {
      clearTimeout(authCheckTimeoutRef.current);
    }
  }, []);

  const checkAuth = useCallback(async (force = false) => {
    // Prevent frequent checks unless forced
    if (!force && Date.now() - lastAuthCheckRef.current < 60000) {
      return;
    }

    try {
      const token = localStorage.getItem(TOKEN_KEY);
      if (!token) {
        clearAuth();
        setLoading(false);
        return;
      }

      const response = await api.get('/auth/me');
      persistAuth(null, response.data);
    } catch (error) {
      console.error('Error checking auth:', error);
      if (error.response?.status === 401) {
        clearAuth();
      }
    } finally {
      setLoading(false);
    }
  }, [clearAuth, persistAuth]);

  // Check auth on mount
  useEffect(() => {
    checkAuth(true);
  }, [checkAuth]);

  // Set up periodic auth check
  useEffect(() => {
    if (!isAuthenticated) return;

    const setupAuthCheck = () => {
      if (authCheckTimeoutRef.current) {
        clearTimeout(authCheckTimeoutRef.current);
      }
      authCheckTimeoutRef.current = setTimeout(() => {
        checkAuth();
        setupAuthCheck();
      }, 5 * 60 * 1000); // Check every 5 minutes
    };

    setupAuthCheck();
    return () => {
      if (authCheckTimeoutRef.current) {
        clearTimeout(authCheckTimeoutRef.current);
      }
    };
  }, [isAuthenticated, checkAuth]);

  const login = async (email, password) => {
    try {
      setLoading(true);
      // Login request
      const response = await api.post('/auth/login', { email, password });
      
      // Store auth data first
      persistAuth(response.data.token, response.data.user);

      toast.success('Successfully logged in!');

      // Force reload the page after successful login
      window.location.href = '/';
      
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      const message = error.response?.data?.message || 'Failed to login';
      toast.error(message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await api.post('/auth/logout');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      clearAuth();
      toast.success('Successfully logged out!');
    }
  };

  const register = async (userData) => {
    try {
      const response = await api.post('/auth/register', userData);
      if (response.data.code) {
        return { 
          success: true, 
          code: response.data.code,
          email: userData.email 
        };
      } else {
        return { success: false, error: 'No verification code received' };
      }
    } catch (error) {
      console.error('Registration error:', error);
      console.error('Error response:', error.response?.data);
      const errorMessage = error.response?.data?.error || error.response?.data?.message || 'Failed to register';
      toast.error(errorMessage);
      throw error;
    }
  };

  const verify = async (verificationData) => {
    try {
      const response = await api.post('/auth/verify', verificationData);
      if (response.data.token) {
        persistAuth(response.data.token, response.data.user);
        toast.success('Account created successfully!');
        
        // Force reload the page after successful verification
        window.location.href = '/';
        
        return { success: true, user: response.data.user };
      }
      return response.data;
    } catch (error) {
      console.error('Verification error:', error);
      const message = error.response?.data?.message || 'Failed to verify account';
      toast.error(message);
      throw error;
    }
  };

  const updateUser = useCallback((userData) => {
    persistAuth(null, userData);
  }, [persistAuth]);

  const requestPasswordReset = async (email) => {
    try {
      const response = await api.post('/auth/forgot-password', { email });
      toast.success('Password reset link sent to your email');
      return response.data;
    } catch (error) {
      const message = error.response?.data?.message || 'Failed to send reset link';
      toast.error(message);
      throw error;
    }
  };

  const resetPassword = async (token, newPassword) => {
    try {
      const response = await api.post(`/auth/reset-password/${token}`, { 
        newPassword 
      });
      toast.success('Password reset successful');
      return response.data;
    } catch (error) {
      const message = error.response?.data?.message || 'Failed to reset password';
      toast.error(message);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    isAuthenticated,
    login,
    logout,
    register,
    verify,
    checkAuth,
    updateUser,
    requestPasswordReset,
    resetPassword
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 