import React from 'react';
import { FaUserFriends, FaUsers, FaFire, FaClock } from 'react-icons/fa';

const FeedNavigation = ({ activeTab, onTabChange }) => {
  const tabs = [
    { id: 'following', label: 'Following', icon: FaUserFriends },
    { id: 'mates', label: 'Mates', icon: FaUsers },
    { id: 'trending', label: 'Trending', icon: FaFire },
    { id: 'latest', label: 'Latest', icon: FaClock },
  ];

  return (
    <div className="flex gap-2 md:gap-3 w-full">
      {tabs.map(({ id, label, icon: Icon }) => (
        <button
          key={id}
          onClick={() => onTabChange(id)}
          className={`flex-1 py-2.5 px-2 md:px-4 flex items-center justify-center gap-1.5 md:gap-2 text-xs md:text-sm font-medium rounded-xl transition-all ${
            activeTab === id
              ? 'bg-gradient-to-r from-purple-500 to-blue-500 text-white shadow-md'
              : 'bg-white text-gray-600 hover:bg-gray-50 shadow-sm'
          }`}
        >
          <Icon className="w-4 h-4" />
          <span className="truncate">{label}</span>
        </button>
      ))}
    </div>
  );
};

export default FeedNavigation; 