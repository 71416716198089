import React from 'react';
import { useRants } from '../context/RantContext';
import {
  HomeIcon,
  ComputerDesktopIcon,
  BuildingLibraryIcon,
  TrophyIcon,
  FilmIcon,
  PuzzlePieceIcon,
  CakeIcon,
  HeartIcon
} from '@heroicons/react/24/outline';

// Using the same categories as CreateRant.js
const categories = [
  { id: 'all', name: 'All', icon: HomeIcon, description: 'View all rants' },
  { id: 'general', name: 'General', icon: HomeIcon, description: 'General discussions and topics' },
  { id: 'tech', name: 'Technology', icon: ComputerDesktopIcon, description: 'Tech news, gadgets, and software' },
  { id: 'politics', name: 'Politics', icon: BuildingLibraryIcon, description: 'Political discussions and news' },
  { id: 'sports', name: 'Sports', icon: TrophyIcon, description: 'Sports news and discussions' },
  { id: 'entertainment', name: 'Entertainment', icon: FilmIcon, description: 'Movies, TV, music, and pop culture' },
  { id: 'gaming', name: 'Gaming', icon: PuzzlePieceIcon, description: 'Video games and gaming culture' },
  { id: 'food', name: 'Food', icon: CakeIcon, description: 'Food, cooking, and restaurants' },
  { id: 'health', name: 'Health', icon: HeartIcon, description: 'Health, fitness, and wellbeing' }
];

const CategorySelector = () => {
  const { selectedCategory, setSelectedCategory, fetchRants } = useRants();

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    fetchRants(1, categoryId);
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-4">Categories</h2>
      <div className="space-y-2">
        {categories.map(({ id, name, icon: Icon, description }) => (
          <button
            key={id}
            onClick={() => handleCategoryChange(id)}
            className={`w-full flex items-center px-4 py-3 rounded-lg transition-all ${
              selectedCategory === id
                ? 'bg-gradient-to-r from-purple-500 to-blue-500 text-white shadow-md'
                : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
            }`}
            title={description}
          >
            <Icon className="w-5 h-5 mr-3" />
            <span className="text-sm font-medium">{name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default CategorySelector; 