import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRants } from '../context/RantContext';
import { useAuth } from '../context/AuthContext';
import { useWebSocket } from '../context/WebSocketContext';
import { motion } from 'framer-motion';
import { getUploadUrl } from '../utils/helpers';
import SignUpPrompt from '../components/SignUpPrompt';
import CommentSection from '../components/CommentSection';
import VoteButtons from '../components/VoteButtons';
import LoadingSpinner from '../components/LoadingSpinner';
import LinkPreview from '../components/LinkPreview';
import ImageViewer from '../components/ImageViewer';
import UserModal from '../components/UserModal';
import ReportModal from '../components/ReportModal';
import BackButton from '../components/BackButton';
import { formatDistanceToNow } from 'date-fns';
import { toast } from 'react-hot-toast';
import { TrashIcon, FlagIcon } from '@heroicons/react/24/outline';
import api from '../utils/axiosConfig';

// Helper function to extract URLs from text
function extractUrls(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.match(urlRegex) || [];
}

const RantDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { fetchRantById, loading, error } = useRants();
  const { isAuthenticated, user } = useAuth();
  const { socket } = useWebSocket();
  const [rant, setRant] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  useEffect(() => {
    const loadRant = async () => {
      try {
        const data = await fetchRantById(id);
        setRant(data);
      } catch (err) {
        console.error('Error loading rant:', err);
      }
    };

    loadRant();
  }, [id, fetchRantById]);

  useEffect(() => {
    if (socket && rant) {
      const handleMessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === 'rantLiked' && data.rantId === rant._id) {
            setRant(prev => ({ ...prev, likes: data.likes }));
          } else if (data.type === 'newComment' && data.comment.rant === rant._id) {
            setRant(prev => ({
              ...prev,
              comments: [data.comment, ...(prev.comments || [])]
            }));
          }
        } catch (error) {
          console.error('Error handling WebSocket message:', error);
        }
      };

      socket.addEventListener('message', handleMessage);

      return () => {
        socket.removeEventListener('message', handleMessage);
      };
    }
  }, [socket, rant]);

  const handleVoteUpdate = (updatedRant) => {
    setRant(prev => ({ ...prev, likes: updatedRant.likes }));
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this rant?')) {
      return;
    }

    try {
      await api.delete(`/rants/${id}`);
      toast.success('Rant deleted successfully');
      navigate('/');
    } catch (error) {
      console.error('Error deleting rant:', error);
      toast.error(error.response?.data?.message || 'Failed to delete rant');
    }
  };

  const handleReportSuccess = () => {
    toast.success('Report submitted successfully');
    setShowReportModal(false);
  };

  const formatContent = (content) => {
    if (!content) return '';
    // Remove URLs from content when they will be shown as previews
    const urls = extractUrls(content);
    if (urls && urls.length > 0) {
      return content.replace(urls[0], '').trim();
    }
    return content;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-500">
        Error loading rant: {error}
      </div>
    );
  }

  if (!rant) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Rant not found
      </div>
    );
  }

  const isOwner = user && rant.user && user._id === rant.user._id;
  const isAdmin = user && (user.isAdmin || user.role === 'admin' || user.role === 'superadmin');
  const canDelete = isOwner || isAdmin;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="min-h-screen bg-gradient-to-br from-purple-50 to-white"
    >
      <div className="max-w-4xl mx-auto px-4 pt-4">
        <BackButton />
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden transform transition-all duration-300 hover:shadow-xl">
          <div className="p-8">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
              <div className="flex items-center">
                <div 
                  className="flex-shrink-0 cursor-pointer transform hover:scale-105 transition-transform duration-200"
                  onClick={() => setShowUserModal(true)}
                >
                  {rant.user?.profileImage ? (
                    <img
                      src={getUploadUrl(rant.user.profileImage)}
                      alt={rant.user.username}
                      className="w-14 h-14 rounded-full object-cover ring-2 ring-offset-2 ring-gray-100"
                    />
                  ) : (
                    <div className="w-14 h-14 rounded-full bg-gradient-to-br from-blue-500 to-purple-500 flex items-center justify-center ring-2 ring-offset-2 ring-gray-100">
                      <span className="text-xl text-white font-medium">
                        {rant.user?.username?.[0]?.toUpperCase()}
                      </span>
                    </div>
                  )}
                </div>
                <div className="ml-4">
                  <button
                    onClick={() => setShowUserModal(true)}
                    className="text-xl font-semibold text-gray-900 hover:text-blue-600 transition-colors duration-200"
                  >
                    {rant.user?.username}
                  </button>
                  <p className="text-sm text-gray-500">
                    {formatDistanceToNow(new Date(rant.createdAt), { addSuffix: true })}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2 sm:ml-4">
                {isAuthenticated && !isOwner && (
                  <button
                    onClick={() => setShowReportModal(true)}
                    className="flex items-center p-2 text-sm font-medium text-red-600 hover:text-white bg-red-50 hover:bg-red-600 rounded-full transition-all duration-200"
                    title="Report rant"
                  >
                    <FlagIcon className="w-5 h-5" />
                  </button>
                )}
                {canDelete && (
                  <button
                    onClick={handleDelete}
                    className="flex items-center p-2 text-sm font-medium text-red-600 hover:text-white bg-red-50 hover:bg-red-600 rounded-full transition-all duration-200"
                    title="Delete rant"
                  >
                    <TrashIcon className="w-5 h-5" />
                  </button>
                )}
              </div>
            </div>

            <div className="prose max-w-none">
              <p className="text-gray-800 whitespace-pre-wrap break-words">
                {formatContent(rant.content)}
              </p>
            </div>

            {extractUrls(rant.content)[0] && (
              <div className="mt-4">
                <LinkPreview url={extractUrls(rant.content)[0]} />
              </div>
            )}

            {/* Media Content */}
            {(rant.images?.length > 0 || rant.video) && (
              <div className="mt-6 grid gap-4 grid-cols-1">
                {/* Video */}
                {rant.video && (
                  <div
                    className="relative aspect-video rounded-xl overflow-hidden bg-black"
                  >
                    <video
                      src={getUploadUrl(rant.video.url)}
                      poster={rant.video.thumbnail ? getUploadUrl(rant.video.thumbnail) : undefined}
                      controls
                      className="w-full h-full"
                      preload="metadata"
                    >
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}

                {/* Images */}
                {rant.images?.length > 0 && (
                  <div className={`grid gap-4 ${
                    rant.images.length === 1 ? 'grid-cols-1' : 'sm:grid-cols-2'
                  }`}>
                    {rant.images.map((image, index) => (
                      <div
                        key={index}
                        onClick={() => setSelectedImage(getUploadUrl(image.url))}
                        className="relative aspect-square rounded-xl overflow-hidden cursor-pointer group bg-black"
                      >
                        <img
                          src={getUploadUrl(image.url)}
                          alt={image.caption || `Image ${index + 1}`}
                          className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                        />
                        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity duration-300" />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            <div className="mt-8 flex items-center justify-between border-t border-gray-100 pt-6">
              <VoteButtons
                rantId={rant._id}
                likes={rant.likes}
                commentCount={rant.comments?.length || 0}
                showCommentBubble={true}
                onVoteUpdate={handleVoteUpdate}
              />
            </div>

            <div className="mt-8 border-t border-gray-100 pt-6">
              {isAuthenticated ? (
                <CommentSection rantId={rant._id} initialComments={rant.comments} />
              ) : (
                <SignUpPrompt message="Join the conversation and share your thoughts!" />
              )}
            </div>
          </div>
        </div>
      </div>

      {selectedImage && (
        <ImageViewer
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}

      {showUserModal && (
        <UserModal
          userId={rant.user?._id}
          onClose={() => setShowUserModal(false)}
        />
      )}

      {showReportModal && (
        <ReportModal
          isOpen={showReportModal}
          onClose={() => setShowReportModal(false)}
          rantId={rant._id}
          onSuccess={handleReportSuccess}
        />
      )}
    </motion.div>
  );
};

export default RantDetail; 