import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMessages } from '../context/MessageContext';
import { motion } from 'framer-motion';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';

const MessageCenter = () => {
  const navigate = useNavigate();
  const { unreadMessages = 0, mateRequests = [] } = useMessages();

  const totalUnread = (unreadMessages || 0) + (Array.isArray(mateRequests) ? mateRequests.length : 0);

  const handleMessageClick = () => {
    navigate('/messages');
  };

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handleMessageClick}
      className="relative p-2 text-gray-600 hover:text-gray-900 focus:outline-none rounded-full hover:bg-gray-100 transition-colors duration-200"
    >
      <ChatBubbleOvalLeftEllipsisIcon className="w-6 h-6" />
      {totalUnread > 0 && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="absolute -top-1 -right-1 flex items-center justify-center"
        >
          <span className="flex h-5 w-5 items-center justify-center rounded-full bg-blue-500 text-xs font-medium text-white ring-2 ring-white">
            {totalUnread}
          </span>
        </motion.div>
      )}
    </motion.button>
  );
};

export default MessageCenter; 