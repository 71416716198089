import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useWebSocket } from './WebSocketContext';
import { useAuth } from './AuthContext';
import api from '../utils/axiosConfig';
import { toast } from 'react-hot-toast';

const MessageContext = createContext();

export const useMessages = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
  const { socket } = useWebSocket();
  const { user } = useAuth();
  const [unreadCount, setUnreadCount] = useState(0);
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const initializationRef = useRef(false);
  const abortController = useRef(new AbortController());
  const requestCache = useRef(new Map());

  // Mark messages as read - define this first
  const markAsRead = useCallback(async (userId) => {
    try {
      await api.post(`/messages/${userId}/read`);
      setUnreadCount(prev => Math.max(0, prev - 1));
      setConversations(prev =>
        prev.map(conv =>
          conv.user._id === userId
            ? { ...conv, unreadCount: 0 }
            : conv
        )
      );
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  }, []);

  // Send a message
  const sendMessage = useCallback(async (recipientId, content) => {
    if (!socket) {
      toast.error('Not connected to chat server');
      return;
    }

    try {
      const messageData = {
        type: 'message',
        data: {
          recipientId,
          content
        }
      };

      socket.send(JSON.stringify(messageData));
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    }
  }, [socket]);

  // Fetch messages for a conversation
  const fetchMessages = useCallback(async (userId) => {
    if (!user || !userId) {
      setMessages([]);
      return [];
    }
    
    try {
      setLoading(true);
      
      // Check cache first
      const cacheKey = `messages-${userId}`;
      const cached = requestCache.current.get(cacheKey);
      if (cached && Date.now() - cached.timestamp < 5000) {
        setMessages(cached.messages);
        setActiveConversation(userId);
        return cached.messages;
      }
      
      // Fetch messages
      const messagesRes = await api.get(`/messages/${userId}`);
      
      // Process messages in a single batch and sort by date
      const processedMessages = messagesRes.data
        .map(message => ({
          ...message,
          read: message.sender._id === user._id ? message.read : true,
          sender: {
            ...message.sender,
            _id: message.sender._id === user._id ? user._id : message.sender._id,
            username: message.sender._id === user._id ? user.username : message.sender.username,
            profileImage: message.sender._id === user._id ? user.profileImage : message.sender.profileImage
          },
          recipient: {
            ...message.recipient,
            _id: message.recipient._id === user._id ? user._id : message.recipient._id,
            username: message.recipient._id === user._id ? user.username : message.recipient.username,
            profileImage: message.recipient._id === user._id ? user.profileImage : message.recipient.profileImage
          }
        }))
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      
      // Cache the results
      requestCache.current.set(cacheKey, {
        timestamp: Date.now(),
        messages: processedMessages
      });
      
      setMessages(processedMessages);
      setActiveConversation(userId);
      
      // Mark messages as read if needed
      const unreadMessages = processedMessages.filter(m => m.sender._id === userId && !m.read);
      if (unreadMessages.length > 0) {
        await markAsRead(userId);
      }

      return processedMessages;
    } catch (error) {
      console.error('Error fetching messages:', error);
      toast.error('Failed to load messages');
      setMessages([]);
      return [];
    } finally {
      setLoading(false);
    }
  }, [user, markAsRead]);

  // Initialize message data with caching
  const initializeMessageData = useCallback(async () => {
    if (!user) return;
    if (initialized && !initializationRef.current) return;
    
    const cacheKey = 'message-init';
    const cached = requestCache.current.get(cacheKey);
    if (cached && Date.now() - cached.timestamp < 5000) {
      setUnreadCount(cached.data.unreadCount);
      setConversations(cached.data.conversations);
      setInitialized(true);
      return;
    }

    try {
      initializationRef.current = true;
      setLoading(true);
      setInitialized(false);
      
      // Fetch all message-related data in parallel
      const [unreadRes, conversationsRes] = await Promise.all([
        api.get('/messages/unread/count', { signal: abortController.current.signal }),
        api.get('/messages/conversations', { signal: abortController.current.signal })
      ]);

      // Cache the results
      requestCache.current.set(cacheKey, {
        timestamp: Date.now(),
        data: {
          unreadCount: unreadRes.data.count,
          conversations: conversationsRes.data
        }
      });

      // Update state in a single batch
      setUnreadCount(unreadRes.data.count);
      setConversations(conversationsRes.data);
      setInitialized(true);
    } catch (error) {
      if (!error.name === 'AbortError') {
        console.error('Error initializing message data:', error);
        setInitialized(false);
      }
    } finally {
      setLoading(false);
      initializationRef.current = false;
    }
  }, [user]);

  // Reset state when user changes
  useEffect(() => {
    if (!user) {
      setUnreadCount(0);
      setConversations([]);
      setActiveConversation(null);
      setMessages([]);
      setInitialized(false);
      setLoading(false);
      initializationRef.current = false;
      requestCache.current.clear();
    } else {
      // Always initialize when user is present
      abortController.current = new AbortController();
      initializeMessageData();
    }

    return () => {
      abortController.current.abort();
    };
  }, [user, initializeMessageData]);

  // Handle new message from WebSocket
  const handleNewMessage = useCallback((message) => {
    if (!message || !user || !message.sender || !message.recipient) {
      console.warn('Invalid message or user data:', { message, userId: user?._id });
      return;
    }

    try {
      // Process message data with consistent user info
      const processedMessage = {
        ...message,
        read: message.sender._id === user._id ? message.read : false,
        sender: message.sender ? {
          ...message.sender,
          _id: message.sender._id === user._id ? user._id : message.sender._id,
          username: message.sender._id === user._id ? user.username : message.sender.username,
          profileImage: message.sender._id === user._id ? user.profileImage : message.sender.profileImage
        } : null,
        recipient: message.recipient ? {
          ...message.recipient,
          _id: message.recipient._id === user._id ? user._id : message.recipient._id,
          username: message.recipient._id === user._id ? user.username : message.recipient.username,
          profileImage: message.recipient._id === user._id ? user.profileImage : message.recipient.profileImage
        } : null
      };

      if (!processedMessage.sender || !processedMessage.recipient) {
        console.warn('Missing sender or recipient in processed message');
        return;
      }

      // Update messages if in active conversation
      if (activeConversation === processedMessage.sender._id || activeConversation === processedMessage.recipient._id) {
        setMessages(prev => {
          if (!Array.isArray(prev)) return [processedMessage];
          
          // Only check for exact duplicate by ID
          if (prev.some(m => m?._id === processedMessage._id)) {
            return prev;
          }
          return [...prev, processedMessage].sort((a, b) => 
            new Date(a?.createdAt || 0) - new Date(b?.createdAt || 0)
          );
        });
      }

      // Update conversations list immediately for received messages
      if (message.sender._id !== user._id) {
        setUnreadCount(prev => prev + 1);
        setConversations(prev => {
          if (!Array.isArray(prev)) return [];
          
          const otherUser = message.sender;
          const existingIndex = prev.findIndex(c => c?.user?._id === otherUser?._id);
          
          const updatedConversations = [...prev];
          if (existingIndex >= 0) {
            updatedConversations[existingIndex] = {
              ...updatedConversations[existingIndex],
              lastMessage: processedMessage,
              unreadCount: ((updatedConversations[existingIndex]?.unreadCount || 0) + 1)
            };
            // Move the conversation to the top
            const [conversation] = updatedConversations.splice(existingIndex, 1);
            return [conversation, ...updatedConversations];
          }
          
          return [{
            user: otherUser,
            lastMessage: processedMessage,
            unreadCount: 1
          }, ...prev];
        });
      }
    } catch (error) {
      console.error('Error processing new message:', error);
    }
  }, [user, activeConversation, initialized]);

  // WebSocket message handler
  useEffect(() => {
    if (!socket || !user) return;

    const handleWebSocketMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (!data || !data.type) return;

        switch (data.type) {
          case 'message_sent':
            // Server confirms message was sent and stored
            if (!data.message || !data.message.recipient) {
              console.warn('Invalid message_sent data:', data);
              return;
            }

            setMessages(prev => {
              if (!Array.isArray(prev)) return [];
              
              const tempId = `temp_${new Date(data.message.createdAt).getTime()}`;
              const withoutTemp = prev.filter(m => m?._id !== tempId);
              const processedMessage = {
                ...data.message,
                sender: {
                  _id: user._id,
                  username: user.username,
                  profileImage: user.profileImage
                },
                recipient: {
                  _id: data.message.recipient._id,
                  username: data.message.recipient.username,
                  profileImage: data.message.recipient.profileImage
                }
              };
              return [...withoutTemp, processedMessage].sort((a, b) => 
                new Date(a?.createdAt || 0) - new Date(b?.createdAt || 0)
              );
            });

            // Update conversations immediately for sent messages
            setConversations(prev => {
              if (!Array.isArray(prev)) return [];
              
              const recipientId = data.message.recipient._id;
              const existingIndex = prev.findIndex(c => c?.user?._id === recipientId);
              
              const updatedConversations = [...prev];
              if (existingIndex >= 0) {
                updatedConversations[existingIndex] = {
                  ...updatedConversations[existingIndex],
                  lastMessage: data.message
                };
                // Move the conversation to the top
                const [conversation] = updatedConversations.splice(existingIndex, 1);
                return [conversation, ...updatedConversations];
              }
              return prev;
            });
            break;

          case 'new_message':
            // Received a new message from another user
            if (!data.message) {
              console.warn('Invalid new_message data:', data);
              return;
            }
            handleNewMessage(data.message);
            break;

          case 'message_error':
            toast.error('Failed to send message');
            break;

          default:
            break;
        }
      } catch (error) {
        console.error('Error handling WebSocket message:', error);
      }
    };

    socket.addEventListener('message', handleWebSocketMessage);
    return () => socket.removeEventListener('message', handleWebSocketMessage);
  }, [socket, user, handleNewMessage]);

  return (
    <MessageContext.Provider value={{
      unreadCount,
      conversations: conversations || [],
      messages: messages || [],
      setMessages,
      setConversations,
      loading,
      activeConversation,
      sendMessage,
      fetchMessages,
      markAsRead,
      fetchConversations: initializeMessageData,
      clearMessages: () => {
        setMessages([]);
        setActiveConversation(null);
      }
    }}>
      {children}
    </MessageContext.Provider>
  );
};

export default MessageContext; 