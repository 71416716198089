import imageCompression from 'browser-image-compression';

// Maximum file sizes in bytes
export const MAX_IMAGE_SIZE = 15 * 1024 * 1024; // 15MB for GIFs and animated WebP
export const MAX_STATIC_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB for static images
export const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // 100MB

// Supported file types
export const SUPPORTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/avif',
  'image/heic',
  'image/heif'
];

export const SUPPORTED_VIDEO_TYPES = [
  'video/mp4',
  'video/webm',
  'video/quicktime', // MOV
  'video/x-msvideo', // AVI
  'video/x-matroska', // MKV
  'video/3gpp',
  'video/x-ms-wmv'
];

// Image compression options
const compressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  preserveExif: true,
};

// Check if a file is an animated image (GIF or animated WebP)
const isAnimatedImage = async (file) => {
  if (file.type === 'image/gif') {
    return true; // Assume all GIFs are animated for simplicity
  }
  
  if (file.type === 'image/webp') {
    // For WebP, we need to check the file header
    const buffer = await file.arrayBuffer();
    const view = new Uint8Array(buffer);
    // Check for ANIM chunk in WebP header
    for (let i = 0; i < view.length - 4; i++) {
      if (view[i] === 0x41 && view[i + 1] === 0x4E && 
          view[i + 2] === 0x49 && view[i + 3] === 0x4D) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Validates and compresses an image file
 * @param {File} file - The image file to process
 * @returns {Promise<{success: boolean, file?: File, error?: string}>}
 */
export const processImageFile = async (file) => {
  try {
    // Validate file type
    if (!SUPPORTED_IMAGE_TYPES.includes(file.type)) {
      return {
        success: false,
        error: `Unsupported image format. Please use one of: ${SUPPORTED_IMAGE_TYPES.map(type => type.split('/')[1].toUpperCase()).join(', ')}`,
      };
    }

    // Check if it's an animated image
    const isAnimated = await isAnimatedImage(file);
    const maxSize = isAnimated ? MAX_IMAGE_SIZE : MAX_STATIC_IMAGE_SIZE;

    // Skip compression for animated images and HEIC/HEIF formats
    if (isAnimated || file.type.includes('heic') || file.type.includes('heif')) {
      if (file.size > maxSize) {
        return {
          success: false,
          error: `File is too large. Maximum size is ${maxSize / (1024 * 1024)}MB for ${isAnimated ? 'animated images' : 'this format'}.`,
        };
      }
      return { success: true, file };
    }

    // Compress image
    const compressedFile = await imageCompression(file, compressionOptions);

    // Validate final size
    if (compressedFile.size > MAX_STATIC_IMAGE_SIZE) {
      return {
        success: false,
        error: 'Image is too large even after compression. Please use a smaller image.',
      };
    }

    return { success: true, file: compressedFile };
  } catch (error) {
    console.error('Error processing image:', error);
    return {
      success: false,
      error: 'Failed to process image. Please try again.',
    };
  }
};

/**
 * Validates a video file
 * @param {File} file - The video file to validate
 * @returns {{success: boolean, error?: string}}
 */
export const validateVideoFile = (file) => {
  // Validate file type
  if (!SUPPORTED_VIDEO_TYPES.includes(file.type)) {
    return {
      success: false,
      error: `Unsupported video format. Please use one of: ${SUPPORTED_VIDEO_TYPES.map(type => type.split('/')[1].toUpperCase()).join(', ')}`,
    };
  }

  // Validate file size
  if (file.size > MAX_VIDEO_SIZE) {
    return {
      success: false,
      error: `Video file is too large. Maximum size is ${MAX_VIDEO_SIZE / (1024 * 1024)}MB.`,
    };
  }

  return { success: true };
};

/**
 * Creates an object URL for preview
 * @param {File} file - The file to create a preview URL for
 * @returns {string} The preview URL
 */
export const createPreviewUrl = (file) => {
  return URL.createObjectURL(file);
};

/**
 * Revokes an object URL
 * @param {string} url - The URL to revoke
 */
export const revokePreviewUrl = (url) => {
  URL.revokeObjectURL(url);
}; 